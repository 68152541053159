export function forYouDTO(response) {
  let forYouList = [];
  if (response && response.rows.length) {
    forYouList.count = response?.count || 0;
    forYouList.rows = response.rows.map((data) => {
      return {
        id: data?.id || 0,
        regionId: data?.regionId || 0,
        investmentRoundId: data?.investmentRoundId || 0,
        elevatorPitchAdmin: data?.elevatorPitchAdmin || "",
        companyLogo: data?.companyLogo || "",
        keyCustomer1: data?.keyCustomer1 || "-",
        keyCustomer2: data?.keyCustomer2 || "-",
        keyCustomer1Logo: data?.keyCustomer1Logo || "",
        keyCustomer2Logo: data?.keyCustomer2Logo || "",
        watchingCount: data?.watchingCount || "",
        publishedAt: data?.publishedAt || "",
        createdAt: data?.createdAt || "",
        proptechCompany: {
          id: data?.proptechCompany?.id || 0,
          name: data?.proptechCompany?.name || "",
        },
        region: {
          id: data?.region?.id || 0,
          name: data?.region?.name || "",
        },
        investmentRound: {
          id: data?.investmentRound?.id || 0,
          name: data?.investmentRound?.name || "",
        },
        revenueRange: {
          id: data?.revenueRange?.id || 0,
          name: data?.revenueRange?.name || "",
        },
        proptechAnalysisNumber: {
          currentNoOfCountries:
            data?.proptechAnalysisNumber?.proptechAnalysisCurrentNoOfCountry
              ?.name || 0,
          currentNoOfCustomers:
            data?.proptechAnalysisNumber?.proptechAnalysisCurrentNoOfCustomer
              ?.name || "",
        },
        proptechAnalysisOverview: {
          id: data?.proptechAnalysisOverview?.id || 0,
          userExperience: data?.proptechAnalysisOverview?.userExperience || 0,
          dealClosing: data?.proptechAnalysisOverview?.dealClosing || 0,
          evolvingAssets: data?.proptechAnalysisOverview?.evolvingAssets || 0,
          decisionMaking: data?.proptechAnalysisOverview?.decisionMaking || 0,
          smarterBuilds: data?.proptechAnalysisOverview?.smarterBuilds || 0,
          connectivity: data?.proptechAnalysisOverview?.connectivity || 0,
          trackRecord: data?.proptechAnalysisOverview?.trackRecord || 0,
          sector1: data?.proptechAnalysisOverview?.sector1 || 0,
          sector2: data?.proptechAnalysisOverview?.sector2 || 0,
          techOffering1: data?.proptechAnalysisOverview?.techOffering1 || 0,
          proptechAnalysisTechOffering1: {
            id:
              data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1
                ?.id || 0,
            icon:
              data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1
                ?.icon || null,
            name:
              data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1
                ?.name || "",
          },
          proptechAnalysisSector1: {
            id:
              data?.proptechAnalysisOverview?.proptechAnalysisSector1?.id || 0,
            icon:
              data?.proptechAnalysisOverview?.proptechAnalysisSector1?.icon ||
              null,
            name:
              data?.proptechAnalysisOverview?.proptechAnalysisSector1?.name ||
              "",
          },
          proptechAnalysisSector2: {
            id:
              data?.proptechAnalysisOverview?.proptechAnalysisSector2?.id || 0,
            icon:
              data?.proptechAnalysisOverview?.proptechAnalysisSector2?.icon ||
              null,
            name:
              data?.proptechAnalysisOverview?.proptechAnalysisSector2?.name ||
              "",
          },
        },
        rffProptechFavorite: data?.rffProptechFavorite.length
          ? data?.rffProptechFavorite
          : [],
        rffProptechNote: [
          {
            note: data?.rffProptechNote[0]?.note || "",
          },
        ],
        rffProptechForYou: [
          {
            id: data?.rffProptechForYou[0].id || 0,
            rffProptechForYouTag: [
              data?.rffProptechForYou[0].rffProptechForYouTag.map(
                (forYouTag) => {
                  return {
                    id: forYouTag?.id || 0,
                    tag: {
                      id: forYouTag?.tag?.id || 0,
                      name: forYouTag?.tag?.name || "",
                    },
                  };
                }
              ),
            ],
          },
        ],
      };
    });
  }

  return forYouList;
}
