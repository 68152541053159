import React, { useState } from "react";
import { Typography, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import search from "../../../assets/Icons/welcome-modal-search-rff.svg";
import bar from "../../../assets/Icons/welcome-modal-chart-rff.svg";
import building from "../../../assets/Icons/welcome-modal-building-rff.svg";
import PTButton from "../../../components/GenericComponents/PTButton";
import "./welcomeModal.scss";
import { Store } from "../../../store/configureStore";
import { Actions } from "../../../store/actions";
import VideoModal from "../../../components/VideoModal";
import { INTRODUCTRY_VIDEO_URL } from "../../constants";
import CloseIcon from "@material-ui/icons/Close";

export default function WelcomeModal() {
  const [open, setOpen] = useState(true);
  const [watchVideoModal, setWatchVideoModal] = React.useState(false);

  const openVideoModal = () => setWatchVideoModal(true);
  const closeVideoModal = () => setWatchVideoModal(false);

  const handleClose = () => {
    setOpen(false);
    Store.dispatch({ type: Actions.RFFProptechs.RFFShowModal, payload: false });
  };

  const handleChangePlanModal = () => {
    Store.dispatch({ type: Actions.RFFUser.SetChangePlanModal, payload: true });
    // handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "768px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="welcome-modal">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="more-filter-modal modal"
      >
        <Box sx={style} className="text-center welcome-modal">
          <div>
            <IconButton className="close-btn" onClick={handleClose}>
              <CloseIcon alt="Icon" />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Welcome to RFF x Place
            </Typography>
            <p className="para-text sm:w-1/2 m-auto">
              The all-in-one platform dedicated to providing curated
              benchmarking and analysis of Proptechs from around the globe.
            </p>
          </div>
          <div className="flex sm:flex-row mb-6 flex-col">
            <div className="flex flex-col items-center w-full mb-5 sm:mb-0 sm:w-auto sm:pr-8 justify-between">
              <div className="flex flex-col items-center">
                <img src={search} alt="search" />
                <p className="para-text">
                  The Place x RFF platform acts as your innovation hub, where
                  RFF attendees can source and analyse the best global Proptech
                  solutions.
                </p>
              </div>
              <span
                className="find-out-more"
                onClick={() =>
                  window.open(
                    "https://www.theproptechconnection.com/saudi-home#the-ptc-rff",
                    "_blank"
                  )
                }
              >
                Find out more
              </span>
            </div>
            <div className="flex flex-col items-center w-full mb-5 sm:mb-0 sm:w-auto sm:pr-8 justify-between">
              <div className="flex flex-col items-center">
                <img src={bar} alt="search" />
                <p className="para-text">
                  RFF attendees have access to the gold level of platform
                  access, giving access to Home, For you and Watchlist.
                </p>
              </div>
              <span
                className="find-out-more"
                onClick={() => handleChangePlanModal()}
              >
                Find out more
              </span>
            </div>
            <div className="w-full flex flex-col items-center sm:mb-0 sm:w-auto  justify-between">
              <div className="flex flex-col items-center">
                <img src={building} alt="building" />
                <p className="para-text">
                  Our methodology distils market intelligence, employing a
                  diligent four stage benchmark and scoring process.
                </p>
              </div>
              <span
                className="find-out-more"
                onClick={() =>
                  window.open(
                    "https://www.theproptechconnection.com/our-process",
                    "_blank"
                  )
                }
              >
                Find out more
              </span>
            </div>
          </div>
          <div className="flex justify-end footer-btn">
            <PTButton
              label="Watch video"
              className="normal-case fill-gray btn mr-4"
              size="large"
              handleOnClickEvent={openVideoModal}
            />
            <PTButton
              label="Get started"
              className="normal-case fill-blue btn"
              size="large"
              handleOnClickEvent={() => handleClose()}
            />
          </div>
        </Box>
      </Modal>
      {watchVideoModal && (
        <VideoModal
          videoUrl={INTRODUCTRY_VIDEO_URL}
          openVideoModal={watchVideoModal}
          closeVideoModal={closeVideoModal}
        />
      )}
    </div>
  );
}
