import React from "react";
import { useSelector } from "react-redux";
import Chart from "../../Chart";
import "./style.scss";
import { Radar_Chart_Labels } from "../../../constants";
import { numbersChartLabels } from "../../../utils/chartData";

export default function TrackRecordTab() {
  // Store
  const { numbersDetails, pastProjects } = useSelector(
    (state) => state.rffProptechDetails
  );
  const { id } = useSelector((state) => state.publicProptechUser);

  return (
    <>
      {numbersDetails ? (
        <>
          <div
            className={`tabpanel-wrapper-place number-tab  ${
              id && "!max-w-full"
            }`}
          >
            <div className="left_col-place">
              <div className="flex items-start">
                {/* Traction block */}
                <h2 className="pr-4">Traction</h2>
              </div>
              <p>{numbersDetails?.proptechCompanyDetails?.futureGoalsAdmin}</p>
              <div className="number-calculation sm:max-w-[434px]">
                <ul>
                  <li className="flex justify-between">
                    <div>
                      <span>Customers</span>
                    </div>
                    <div>
                      <span className="gray-bg item-square">
                        {
                          numbersDetails?.proptechAnalysisCurrentNoOfCustomer
                            ?.name
                        }
                      </span>
                    </div>
                  </li>
                  <li className="flex justify-between">
                    <div>
                      <span>Countries</span>
                    </div>
                    <div>
                      <span className="gray-bg item-square">
                        {
                          numbersDetails?.proptechAnalysisCurrentNoOfCountry
                            ?.name
                        }
                      </span>
                    </div>
                  </li>
                  <li className="flex justify-between">
                    <div>
                      <span>Projects</span>
                    </div>
                    <div>
                      <span className="gray-bg item-square">
                        {
                          numbersDetails?.proptechAnalysisCurrentGrossMargin
                            ?.name
                        }
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="right_col-place"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex items-start">
                <h2 className="pr-4">{Radar_Chart_Labels.trackRecord}</h2>
              </div>
              <div className="chart-wrapper">
                <Chart
                  labels={
                    // numbersDetails?.placeRadars?.length
                    //   ? numbersDetails?.placeRadars?.map((i) =>
                    //       i.placeRadarPoint?.rada?.split(" ")
                    //     )
                    // :
                    numbersChartLabels
                  }
                  items={
                    numbersDetails?.placeRadars?.length
                      ? numbersDetails?.placeRadars?.map((i) => i.value)
                      : []
                  }
                  step={2}
                  label={"Rating"}
                  maximumValue={10}
                />
              </div>
            </div>
            <div className="market-growth-1 border-none key-metrics-1 bg-[#F7F7F7] mx-2">
              <div className="flex items-start">
                <h2 className="pr-4">Past projects</h2>
              </div>
              <div className="metrics-wrapper">
                <div className="metrics-item">
                  <span>Amount</span>
                  <p>
                    {pastProjects?.number_details?.pastProjectRange?.name ||
                      "-"}
                  </p>
                </div>
                <div className="metrics-item">
                  <span>Geography</span>
                  <p>
                    {pastProjects?.number_details?.placeGeography?.name || "-"}
                  </p>
                </div>
                <div className="metrics-item">
                  <span>Scale</span>
                  <p>
                    {pastProjects?.number_details?.projectScale?.name || "-"}
                  </p>
                </div>
                <div className="metrics-item">
                  <span>
                    {pastProjects?.company_details
                      ?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat
                      ?.name || "-"}
                  </span>
                  <p>
                    {pastProjects?.company_details
                      ?.proptechAnalysisCompanyProfile?.currentTraction2 || "-"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
