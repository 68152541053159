import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { CircularProgress, IconButton, Tab, Tabs, Button } from "@mui/material";
import { tabPanels, buttonPanel } from "../../utils/tabPanels";
import VideoModal from "../../../components/VideoModal";
import videoIcon from "../../../assets/Icons/ic-video-rff.svg";
import {
	FREE_MEMBERSHIP_MEETING_COUNT,
	INTRODUCTRY_VIDEO_URL,
} from "../../constants";
import "../../pages/Dashboard/dashboard.scss";
import "./navbar.scss";
import { Store } from "../../../store/configureStore";
import { Actions } from "../../../store/actions";
import { getRFFLocalStorageUserData } from "../../../utils/session";
import CompanyLogo from "../CompanyLogo";
import RFFLogo from "../../../assets/Images/PIF-white-logo.svg";
import ProjectModal from "../ProjectModal";

export default function NavBar({ value, setValue }) {
	// Store
	const { showModal } = useSelector((state) => state.rffProptechs);
	const rffCountDetails = useSelector((state) => state.rffUser.rffCountDetails);
	const userData = getRFFLocalStorageUserData() || null;
	const { rffProjectTabData } = useSelector((state) => state.rffProptechs);

	// State
	const [watchVideo, setWatchVideo] = React.useState(showModal);
	const [watchVideoModal, setWatchVideoModal] = React.useState(false);
	const [showProjectModal, setShowProjectModal] = React.useState(false);

	const handleCloseWatchVideo = () => {
		setWatchVideo(false);
		Store.dispatch({ type: Actions.RFFProptechs.RFFShowModal, payload: false });
	};
	const openVideoModal = () => setWatchVideoModal(true);
	const closeVideoModal = () => setWatchVideoModal(false);

	const a11yProps = (index) => {
		return {
			id: `vertical-tab-${index}`,
			"aria-controls": `vertical-tabpanel-${index}`,
		};
	};

	// Handling tab change event
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className="h-full">
			<div className="left-bottom-wrapper relative h-full bg-[#0f2e29] flex flex-col justify-between">
				<div className="left-menu-place">
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={value}
						onChange={handleChange}
						aria-label="Vertical tabs example"
					>
						{tabPanels.map((tabPanel, index) => {
							return (
								<Tab
									key={index}
									label={
										<>
											<div className="w-full flex items-center pl-9 menu-item">
												<svg {...tabPanel?.style}>
													<path {...tabPanel?.path} />
												</svg>
												<div className="ml-4 flex items-center justify-center text-[#FFFFFF] font-semibold text-base leading-4 not-italic">
													{tabPanel?.panel}
												</div>
											</div>
										</>
									}
									{...a11yProps(index)}
								/>
							);
						})}
					</Tabs>
					<div
						onClick={() => setShowProjectModal(true)}
						style={{
							display: "flex",
							minHeight: "48px",
							alignItems: "center",
							justifyContent: "center",
							cursor: "pointer",
							margin: "6px",
						}}
					>
						<div className="w-full flex items-center pl-8 menu-item">
							<svg {...buttonPanel[0].style}>
								<path {...buttonPanel[0].path} />
							</svg>
							<div className="ml-4 flex items-center justify-center text-[#FFFFFF] font-semibold text-base leading-4 not-italic">
								{buttonPanel[0].panel}
							</div>
						</div>
					</div>
				</div>
				{/*****Meeting Booked Section******/}
				{/* Need to check if user already gets free subscription or not */}
				{!watchVideo &&
					!rffCountDetails?.isFreePlanClaimed &&
					rffCountDetails?.completedMeetingCount >= 0 &&
					rffCountDetails?.completedMeetingCount <
						FREE_MEMBERSHIP_MEETING_COUNT && (
						<div className="rounded-lg m-4 p-6 flex flex-col content-start bg-[#ffffff0d]">
							<div className="progress-area">
								<CircularProgress
									variant="determinate"
									value={Math.floor(
										(rffCountDetails.completedMeetingCount /
											FREE_MEMBERSHIP_MEETING_COUNT) *
											100
									)}
								/>
								<span className="data">
									{rffCountDetails.completedMeetingCount}
								</span>
							</div>
							<div className="mt-2">
								<div className="font-semibold text-sm leading-5 my-1 not-italic font-['Poppins'] text-[#FFFFFF]">
									Meetings booked
								</div>
								<div className="font-normal text-xs leading-4 my-1 not-italic font-['Poppins'] text-[#C9CDD4]">
									Book{" "}
									<span className="text-[#FF4100]">
										{FREE_MEMBERSHIP_MEETING_COUNT -
											rffCountDetails.completedMeetingCount}{" "}
										more meetings
									</span>{" "}
									and get a free platinum plan membership.
								</div>
								<div className="font-semibold text-xs leading-5 not-italic font-['Poppins'] text-[#FFFFFF] cursor-pointer inline-block">
									Find out more
								</div>
							</div>
						</div>
					)}

				{watchVideo && (
					<div className="rounded-lg m-4 p-6 flex flex-col content-start bg-[#ffffff0d] relative">
						<IconButton className="close-btn" onClick={handleCloseWatchVideo}>
							<CloseIcon className="text-white" color="#FFFFFF" />
						</IconButton>
						<img
							src={videoIcon}
							alt="Icon"
							className="w-10"
							style={{ height: 25 }}
						/>
						<div className="mt-2">
							<div className="font-semibold text-sm leading-5 my-1 not-italic font-['Poppins'] text-[#FFFFFF]">
								New to Place?
							</div>
							<div className="font-normal text-xs leading-4 my-1 not-italic font-['Poppins'] text-[#C9CDD4]">
								Watch our introductory explainer video
							</div>
							<div
								className="font-semibold text-xs leading-5 not-italic font-['Poppins'] text-[#FFFFFF] cursor-pointer inline-block"
								onClick={openVideoModal}
							>
								Watch video
							</div>
						</div>
					</div>
				)}
				{userData !== null ? (
					<div>
						<div className="bg-[#005c4d] p-6 gap-3 flex items-start">
							<div className="flex gap-4 flex-wrap items-start">
								<div
									className="bg-[#005c4d] flex items-start overflow-hidden justify-start"
									// onClick={() => value !== 3 && setValue(3)}
								>
									{/* <CompanyLogo companyLogo={RFFLogo} name={"RFF"} /> */}
									<div className="image-wrapper cursor-pointer flex items-center overflow-hidden justify-center">
										<img
											src={RFFLogo}
											alt={""}
											style={{
												height: "51px",
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						{/* <div className="bg-[#005c4d] p-6 gap-3 flex  items-center">
              {userData?.company && (
                <div className="flex gap-4 flex-wrap items-center">
                  <div
                    className="bg-white flex items-center overflow-hidden justify-center w-[58px] h-[58px] border border-[#C9CDD5] rounded-xl"
                    onClick={() => value !== 3 && setValue(3)}
                  >
                    <CompanyLogo
                      companyLogo={
                        rffProjectTabData?.companyOtherDetails?.companyLogo ||
                        userData?.company?.companyLogo
                      }
                      name={
                        rffProjectTabData?.companyOtherDetails?.rffCompany
                          ?.name || userData?.company?.name
                      }
                    />
                  </div>

                  <div>
                    <p className="text-[#C9CDD5] text-xs leading-4">
                      Curated for <br />{" "}
                      <b>
                        {rffProjectTabData?.companyOtherDetails?.rffCompany
                          ?.name || userData?.company?.name}
                      </b>
                    </p>
                  </div>
                </div>
              )}
            </div> */}
					</div>
				) : null}
			</div>

			{watchVideoModal && (
				<VideoModal
					videoUrl={INTRODUCTRY_VIDEO_URL}
					openVideoModal={watchVideoModal}
					closeVideoModal={closeVideoModal}
				/>
			)}
			{showProjectModal ? (
				<ProjectModal
					showProjectModal={showProjectModal}
					setShowProjectModal={setShowProjectModal}
				/>
			) : null}
		</div>
	);
}
