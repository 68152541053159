import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  RFF_VALIDATE_LINK,
  RFF_VALIDATE_PASSWORD,
} from "../../../../constants/APIs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../components/Loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import PTInput from "../../../../components/GenericComponents/PTInput";
import PTButton from "../../../../components/GenericComponents/PTButton";
import LeftLogo from "../../../../components/LeftLogo";
import { useSelector } from "react-redux";
import { dispatch } from "../../../../utils/utility/store";
import { Actions } from "../../../../store/actions";

const PlacePublicAuth = () => {
  const [loading, setLoading] = useState(false);
  const [isValidLink, setIsValidLink] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const initialValues = {
    password: "",
  };

  const publicProptechUser = useSelector((state) => state.publicProptechUser);

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      verifyPassword(
        {
          password: values.password,
        },
        resetForm
      );
    },
  });

  const verifyPassword = (data) => {
    setLoading(true);
    axios
      .post(`${RFF_VALIDATE_PASSWORD}/${params?.id}`, data)
      .then((res) => {
        setLoading(false);
        if (res.data?.data) {
          setLoginError("");
          dispatch(Actions.PublicProptechUser.SetIsVerified, true);
          dispatch(Actions.PublicProptechUser.SetId, params.id);
          navigate(`${location.pathname}/proptech`, {
            state: {
              id: params?.id,
              isVerified: true,
            },
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        setLoginError(
          err?.response?.data?.message ||
            "Something went wrong, please try again later"
        );
      });
  };

  useEffect(() => {
    if (publicProptechUser?.isVerified) {
      navigate(`${location.pathname}/proptech`);
    }
    if (params?.id) {
      setLoading(true);
      axios
        .get(`${RFF_VALIDATE_LINK}/${params?.id}`)
        .then((res) => {
          setLoading(false);
          if (res.data?.data) {
            setIsValidLink(true);
          } else {
            setIsValidLink(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setIsValidLink(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div className="w-screen flex proptech-layout-wrapper flex-wrap">
        <div className="w-full md:w-3/5">
          <LeftLogo />
        </div>
        <div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
          <div className="wrapper-container">
            {isValidLink ? (
              <>
                <div className="justify-start flex-col">
                  <div className="text-wrapper">
                    <h2>Enter password to continue</h2>
                  </div>
                  {loginError ? (
                    <div className="danger-login">
                      <span>{loginError}</span>
                    </div>
                  ) : null}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-wrapper">
                      <div
                        className={
                          "form-group " +
                          (formik.touched.password && formik.errors.password
                            ? "error-parent"
                            : "") +
                          (!formik.errors.password && formik.values.password
                            ? "success-attempt"
                            : "")
                        }
                      >
                        <PTInput
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          label="Password"
                          fullWidth
                          className="form-control"
                          handleOnChangeEvent={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <span className="error-txt">
                            {formik.errors.password}
                          </span>
                        )}
                      </div>

                      <PTButton
                        label={"Submit"}
                        type="submit"
                        className="normal-case w-full fill-blue btn"
                        size="large"
                      />
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <>
                {!loading && (
                  <div className="danger-login">
                    <h2 className="text-[#DE2828]">
                      The link you followed has expired.
                    </h2>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacePublicAuth;
