import { configs } from "../Configs";

// ****** Base URL ****** //
export const BASE_URL = configs.api;

// ******  Place / (RFF) ****** //
export const RFF_SIGNUP_API = `${BASE_URL}/rff/auth/signup`;
export const RFF_FORGOT_PASSWORD_API = `${BASE_URL}/rff/auth/forgotPassword`;
export const RFF_RESET_PASSWORD_API = `${BASE_URL}/rff/auth/resetPassword`;
export const RFF_CHANGE_PASSWORD_API = `${BASE_URL}/rff/auth/changePassword`;
export const RFF_SEND_EMAIL_VERIFICATION = `${BASE_URL}/rff/auth/re-send-email-invitation`;
export const RFF_PROPTECH_LISTING_HOME = `${BASE_URL}/rff/proptechs/home`;
export const RFF_LOGIN_API = `${BASE_URL}/rff/auth/login`;
export const RFF_PLAN_DETAILS_API = `${BASE_URL}/rff/plans/refreshPlanDetails`;
export const RFF_FEATURELIST = `${BASE_URL}/rff/proptechs/featured`;
export const RFF_NOTIFICATIONS_API = `${BASE_URL}/rff/notifications`;
export const RFF_FORYOU = `${BASE_URL}/rff/proptechs/foryou`;
export const RFF_WATCHLIST = `${BASE_URL}/rff/proptechs/favorites`;
export const RFF_PROPTECH_LISTING = `${BASE_URL}/rff/proptechs`;
export const RFF_ACTIVEUSERSLIST_API = `${BASE_URL}/rff/auth/activeUsers`;
export const RFF_USERS_API = `${BASE_URL}/rff/auth/users`;
export const RFF_INVITE_API = `${BASE_URL}/rff/auth/invite`;
export const RFF_MASTER_API = `${BASE_URL}/rff/master`;
export const RFF_REGIONS_API = `${RFF_MASTER_API}/regions`;
export const RFF_REVENUERANGES_API = `${RFF_MASTER_API}/revenueRanges`;
export const RFF_INVESTMENT_ROUNDS_API = `${RFF_MASTER_API}/investmentRounds`;
export const RFF_INVESTMENT_STAGES_API = `${RFF_MASTER_API}/investmentStages`;
export const RFF_KEYBIZMODELS_API = `${RFF_MASTER_API}/keyBizModels`;
export const RFF_COMPANYPROFILE_API = `${BASE_URL}/rff/auth/companyProfile`;
export const RFF_MEETINGSBASE_API = `${BASE_URL}/rff/meetings`;
export const RFF_SCHEDULEMEETING_API = `${BASE_URL}/rff/meetings/schedule`;
export const RFF_UPCOMINGMEETING_API = `${BASE_URL}/rff/meetings/upcoming`;
export const RFF_PREVIOUSMEETING_API = `${BASE_URL}/rff/meetings/previous`;
export const RFF_PLANS_API = `${BASE_URL}/rff/master/plans`;
export const RFF_PLAN_REQUEST = `${BASE_URL}/rff/plans/choosePlan`;
export const RFF_PROJECTS = `${BASE_URL}/rff/projects`;

/** Public apis */
export const RFF_VALIDATE_LINK = `${RFF_PROPTECH_LISTING}/validateLink`;
export const RFF_VALIDATE_PASSWORD = `${RFF_PROPTECH_LISTING}/validatePassword`;
