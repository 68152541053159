export const Actions = {
  Loader: {
    LoadingStart: "LOADING_START",
    LoadingStop: "LOADING_STOP",
  },
  RFFAuth: {
    RFFLoginSuccess: "RFF_AUTH_LOGIN_SUCCESS",
    RFFLoginFailed: "RFF_AUTH_LOGIN_FAILED",
    RFFReset: "RFF_AUTH_RESET",
  },
  RFFUser: {
    RFFUserSet: "RFF_USER_SET",
    RFFUserRemove: "RFF_USER_REMOVE",
    SetNotificationCheckUnread: "RFF_NOTIFICATION_CHECK_UNREAD",
    SetNotifications: "RFF_SET_NOTIFICATIONS",
    setNotificationLoading: "SET_NOTIFICATION_LOADING",
    SetActivePlan: "SET_RFF_ACTIVE_PLAN",
    SetCountDetails: "SET_RFF_COUNT_DETAILS",
    SetChangePlanModal: "SET_RFF_CHANGE_PLAN_MODAL",
    RFFUserReset: "RFF_USER_RESET",
    SetNavTabs: "SET_RFF_NAV_TABS",
    SetActiveMainTab: "SET_ACTIVE_MAIN_TAB",
  },
  RFFProptechs: {
    RFFProptechsSet: "RFF_PROPTECHS_LIST_SET",
    RFFWatchlistSet: "RFF_WATCHLIST_SET",
    RFFWatchlistPageSet: "RFF_WATCHLIST_PAGE_SET",
    RFFWatchlistPageSizeSet: "RFF_WATCHLIST_PAGESIZE_SET",
    RFFResetList: "RFF_RESET_LIST",
    RFFProptechsReset: "RFF_PROPTECHS_RESET",
    RFFShowModal: "RFF_SHOW_MODAL",
    RFFSetFilter: "RFF_SET_FILTER_OPTION",
    RFFSetOrder: "RFF_SET_ORDER",
    RFFSetOrderBy: "RFF_SET_ORDER_BY",
    RFFSetPage: "RFF_SET_PAGE",
    RFFSetPageSize: "RFF_SET_PAGESIZE",
    RFFSetLoading: "RFF_SET_LOADING",
    RFFSetMasterData: "RFF_SET_MASTERDATA",
    RFFSetActiveUsers: "RFF_SET_ACTIVEUSERS",
    RFFSetActiveTab: "RFF_SET_ACTIVETAB",
    RFFProjectTabData: "RFF_PROJECT_TAB_DATA",
  },
  RFFForYou: {
    RFFForyouSet: "RFF_FORYOU_LIST_SET",
    RFFSetPage: "RFF_SET_PAGE_FORYOU",
    RFFSetPageSize: "RFF_SET_PAGESIZE_FORYOU",
  },
  RFFProptechDetails: {
    setRFFProptechDetails: "SET_RFF_PROPTECH_DETAILS",
    setRFFOverviewDetails: "SET_RFF_OVERVIEW_DETAILS",
    setRFFMarketDetails: "SET_RFF_MARKET_DETAILS",
    setRFFNumbersDetails: "SET_RFF_NUMBERS_DETAILS",
    setRFFDeployAndUseDetails: "SET_RFF_DEPLOY_AND_USE_DETAILS",
    setRFFDesignReviewDetails: "SET_RFF_DESIGNREVIEW_DETAILS",
    setRFFMarketInsightsDetails: "SET_RFF_MARKETINSIGHTS_DETAILS",
    setRFFCompanyProfileDetails: "SET_RFF_COMPANYPROFILE_DETAILS",
    setRFFApiTimeout: "SET_RFF_APITIMEOUT",
    RFFProptechDetailsReset: "RFF_PROPTECH_DETAILS_RESET",
    setRFFKeyAttributesDetails: "SET_RFF_KEY_ATTRIBUTES_DETAILS",
    setRFFPastProjectsDetails: "SET_RFF_PAST_PROJECT_DETAILS",
  },
  Snackbar: {
    SnackbarShow: "SNACKBAR_SHOW",
    SnackbarHide: "SNACKBAR_HIDE",
    SnackbarMessage: "SNACKBAR_MESSAGE",
    SnackbarMessageType: "SNACKBAR_MESSAGE_TYPE",
    SnackbarReset: "SNACKBAR_RESET",
  },
  PublicProptechUser: {
    SetId: "SET_ID",
    SetIsVerified: "SET_IS_VERIFIED",
    SetUserReset: "USER_RESET",
  },
};
