export function numbersTabRFFDTO(data) {
  return {
    id: data?.id || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    cashProfile:
      data?.cashProfile || data?.cashProfile === 0 ? data?.cashProfile : null,
    profitability:
      data?.profitability || data?.profitability === 0
        ? data?.profitability
        : null,
    stickiness:
      data?.stickiness || data?.stickiness === 0 ? data?.stickiness : null,
    speedToScale:
      data?.speedToScale || data?.speedToScale === 0
        ? data?.speedToScale
        : null,
    salesConversion:
      data?.salesConversion || data?.salesConversion === 0
        ? data?.salesConversion
        : null,
    customerType:
      data?.customerType || data?.customerType === 0
        ? data?.customerType
        : null,
    proptechCompanyDetails: {
      futureGoalsAdmin: data?.proptechCompanyDetails?.futureGoalsAdmin || "-",
    },
    proptechAnalysisCurrentNoOfCustomer: {
      id: data?.proptechAnalysisCurrentNoOfCustomer?.id || 0,
      name: data?.proptechAnalysisCurrentNoOfCustomer?.name || "-",
    },
    proptechAnalysisCurrentNoOfCountry: {
      id: data?.proptechAnalysisCurrentNoOfCountry?.id || 0,
      name: data?.proptechAnalysisCurrentNoOfCountry?.name || "-",
    },
    proptechAnalysisCurrentGrossMargin: {
      id: data?.proptechAnalysisCurrentGrossMargin?.id || 0,
      name: data?.proptechAnalysisCurrentGrossMargin?.name || "-",
    },
    proptechAnalysisFutureNoOfCustomer: {
      id: data?.proptechAnalysisFutureNoOfCustomer?.id || 0,
      name: data?.proptechAnalysisFutureNoOfCustomer?.name || "-",
    },
    proptechAnalysisFutureNoOfCountry: {
      id: data?.proptechAnalysisFutureNoOfCountry?.id || 0,
      name: data?.proptechAnalysisFutureNoOfCountry?.name || "-",
    },
    proptechAnalysisFutureGrossMargin: {
      id: data?.proptechAnalysisFutureGrossMargin?.id || 0,
      name: data?.proptechAnalysisFutureGrossMargin?.name || "-",
    },
    proptechAnalysisGrowthBlocker1: {
      id: data?.proptechAnalysisGrowthBlocker1?.id || 0,
      icon: data?.proptechAnalysisGrowthBlocker1?.icon || null,
      name: data?.proptechAnalysisGrowthBlocker1?.name || "-",
    },
    proptechAnalysisGrowthBlocker2: {
      id: data?.proptechAnalysisGrowthBlocker2?.id || 0,
      icon: data?.proptechAnalysisGrowthBlocker2?.icon || null,
      name: data?.proptechAnalysisGrowthBlocker2?.name || "-",
    },
    proptechAnalysisGrowthBlocker3: {
      id: data?.proptechAnalysisGrowthBlocker3?.id || 0,
      icon: data?.proptechAnalysisGrowthBlocker3?.icon || null,
      name: data?.proptechAnalysisGrowthBlocker3?.name || "-",
    },
    placeRadars: data?.proptechCompanyDetails?.proptechRadarValues,
  };
}
