export default function rffProptechs(
  initialState = {
    rffProptechs: null,
    rffWatchList: null,
    rffWatchListPage: 0,
    rffWatchListPageSize: 10,
    rffMasterData: null,
    rffActiveUsers: [],
    showModal: false,
    filter: null,
    orderBy: "createdAt",
    order: "DESC",
    page: 0,
    size: 10,
    isLoading: false,
    activeTabId: 0,
    rffProjectTabData: null,
  },
  action
) {
  switch (action.type) {
    case "RFF_SHOW_MODAL":
      return {
        ...initialState,
        showModal: action.payload,
      };

    case "RFF_PROPTECHS_LIST_SET":
      return {
        ...initialState,
        rffProptechs: action.payload,
      };

    case "RFF_WATCHLIST_SET":
      return {
        ...initialState,
        rffWatchList: action.payload,
      };

    case "RFF_WATCHLIST_PAGE_SET":
      return {
        ...initialState,
        rffWatchListPage: action.payload,
      };

    case "RFF_WATCHLIST_PAGESIZE_SET":
      return {
        ...initialState,
        rffWatchListPageSize: action.payload,
      };

    case "RFF_SET_ORDER":
      return {
        ...initialState,
        order: action.payload,
      };

    case "RFF_SET_FILTER_OPTION":
      return {
        ...initialState,
        filter: action.payload,
      };

    case "RFF_SET_ORDER_BY":
      return {
        ...initialState,
        orderBy: action.payload,
      };

    case "RFF_SET_PAGE":
      return {
        ...initialState,
        page: action.payload,
      };

    case "RFF_SET_PAGESIZE":
      return {
        ...initialState,
        size: action.payload,
      };

    case "RFF_SET_LOADING":
      return {
        ...initialState,
        isLoading: action.payload,
      };

    case "RFF_SET_MASTERDATA":
      return {
        ...initialState,
        rffMasterData: action.payload,
      };

    case "RFF_SET_ACTIVEUSERS":
      return {
        ...initialState,
        rffActiveUsers: action.payload,
      };

    case "RFF_SET_ACTIVETAB":
      return {
        ...initialState,
        activeTabId: action.payload,
      };

    case "RFF_PROJECT_TAB_DATA":
      return {
        ...initialState,
        rffProjectTabData: action.payload,
      };

    case "RFF_RESET_LIST":
      return {
        ...initialState,
        rffProptechs: null,
        rffWatchList: null,
      };

    case "RFF_PROPTECHS_RESET":
      return {
        ...initialState,
        rffProptechs: null,
        rffWatchList: null,
        rffWatchListPage: 0,
        rffWatchListPageSize: 10,
        rffMasterData: null,
        rffActiveUsers: [],
        showModal: false,
        filter: null,
        orderBy: "createdAt",
        order: "DESC",
        page: 1,
        size: 10,
        isLoading: false,
        activeTabId: 0,
        rffProjectTabData: null,
      };

    default:
      return initialState;
  }
}
