import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#0f2e29",
		},
		secondary: {
			main: "#FF4100",
		},
		gray: {
			main: "#F0F3F5",
		},
		yellow: {
			main: "#FFD84F",
		},
		white: {
			main: "white",
		},
	},
});

export const tooltipTheme = createTheme({
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontSize: "2em",
				color: "yellow",
				backgroundColor: "red",
			},
		},
	},
});

export const iconColor = "#70778E";
