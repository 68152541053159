//rff session

export const setRFFAccessToken = (data) => {
  localStorage.setItem("rffAccessToken", JSON.stringify(data));
};

export const getRFFAccessToken = () => {
  return JSON.parse(localStorage.getItem("rffAccessToken"));
};

export const setRememberIdRFF = (data) => {
  localStorage.setItem("rffEmailToRemember", JSON.stringify(data));
};

export const getRememberIdRFF = () => {
  return JSON.parse(localStorage.getItem("rffEmailToRemember"));
};

export const getRFFLocalStorageUserData = () => {
  return JSON.parse(localStorage.getItem("rffUserData"));
};

export const setRFFLocalStorageUserData = (data) => {
  localStorage.setItem("rffUserData", JSON.stringify(data));
};

export const removeRFFSessionToken = () => {
  localStorage.removeItem("rffUserData");
  localStorage.removeItem("rffAccessToken");
  window.location.href = "/login";
};
