export function designReviewTabRFFDTO(data) {
  let radarDetails = {};
  data?.proptechCompanyDetails?.proptechRadarValues?.forEach((i) => {
    radarDetails[i?.placeRadarPoint?.rada?.toLowerCase()] = Number(i?.value);
  });
  return {
    id: data?.id || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    visualDesignBranding:
      data?.visualDesignBranding || data?.visualDesignBranding === 0
        ? data?.visualDesignBranding
        : null,
    intuitiveness:
      data?.intuitiveness || data?.intuitiveness === 0
        ? data?.intuitiveness
        : null,
    efficiency:
      data?.efficiency || data?.efficiency === 0 ? data?.efficiency : null,
    feedback: data?.feedback || data?.feedback === 0 ? data?.feedback : null,
    errorPreventionRecovery:
      data?.errorPreventionRecovery || data?.errorPreventionRecovery === 0
        ? data?.errorPreventionRecovery
        : null,
    support: data?.support || data?.support === 0 ? data?.support : null,
    visualDesignBrandingText: data?.visualDesignBrandingText || "-",
    intuitivenessText: data?.intuitivenessText || "-",
    efficiencyText: data?.efficiencyText || "-",
    feedbackText: data?.feedbackText || "-",
    errorPreventionRecoveryText: data?.errorPreventionRecoveryText || "-",
    supportText: data?.supportText || "-",
    overview1: data?.overview1 || "",
    overview2: data?.overview2 || "",
    overview3: data?.overview3 || "",
    overview4: data?.overview4 || "",
    overview5: data?.overview5 || "",
    noOverview:
      data?.overview1 ||
      data?.overview2 ||
      data?.overview3 ||
      data?.overview4 ||
      data?.overview5 ||
      true,
    placeRadars: data?.proptechCompanyDetails?.proptechRadarValues,
    radarDetails,
  };
}
