import { RFF_NOTIFICATIONS_API } from "../../../../constants/APIs";
import { Actions } from "../../../../store/actions";
import { RFF_API } from "../../../../utils/utility/API-call";
import { handleError, handleSuccess } from "../../../../utils/utility/snackbar";
import { dispatch } from "../../../../utils/utility/store";
import { notificationsListDTO } from "../../dtos/notifications";

export const setBadgeVisibility = () => {
  RFF_API()
    .get(RFF_NOTIFICATIONS_API + "/checkUnread")
    .then((res) => {
      handleSuccess(res);
      dispatch(Actions.RFFUser.SetNotificationCheckUnread, res.data.data);
    })
    .catch((error) => handleError(error));
};

export const getNotifications = () => {
  dispatch(Actions.RFFUser.setNotificationLoading, true);
  RFF_API()
    .get(RFF_NOTIFICATIONS_API)
    .then((res) => {
      handleSuccess(res);
      dispatch(
        Actions.RFFUser.SetNotifications,
        notificationsListDTO(res.data.data.rows)
      );
      dispatch(Actions.RFFUser.SetNotificationCheckUnread, false);
      dispatch(Actions.RFFUser.setNotificationLoading, false);
    })
    .catch((error) => {
      dispatch(Actions.RFFUser.setNotificationLoading, false);
      handleError(error);
    });
};

export const readNotification = (rffNotificationId) => {
  RFF_API()
    .post(RFF_NOTIFICATIONS_API, {
      rffNotificationId,
    })
    .then((res) => {
      // handleSuccess(res)
      getNotifications();
    })
    .catch((error) => handleError(error));
};

export const clearNotification = () => {
  RFF_API()
    .post(`${RFF_NOTIFICATIONS_API}/clearAllNotification`)
    .then((res) => {
      dispatch(Actions.RFFUser.SetNotifications, []);
      handleSuccess(res);
    })
    .catch((error) => handleError(error));
};
