export default function rffAuth(
  initialState = {
    loggedIn: false,
  },
  action
) {
  switch (action.type) {
    case "RFF_AUTH_LOGIN_SUCCESS":
      return {
        ...initialState,
        loggedIn: true,
      };

    case "RFF_AUTH_LOGIN_FAILED":
      return {
        ...initialState,
        loggedIn: false,
      };

    case "RFF_AUTH_RESET":
      return {
        ...initialState,
        loggedIn: false,
      };

    default:
      return initialState;
  }
}
