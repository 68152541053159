export default function rffUser(
  initialState = {
    user: null,
    notificationCheckUnread: false,
    notifications: [],
    notificationLoading: false,
    rffPlanDetails: null,
    rffCountDetails: null,
    changePlanModal: false,
    proptechTabsAssigned: [],
    activeMainTab: null,
  },
  action
) {
  switch (action.type) {
    case "RFF_USER_SET":
      return {
        ...initialState,
        user: action.payload,
      };

    case "RFF_USER_REMOVE":
      return {
        ...initialState,
        user: null,
      };

    case "RFF_NOTIFICATION_CHECK_UNREAD":
      return {
        ...initialState,
        notificationCheckUnread: action.payload,
      };

    case "RFF_SET_NOTIFICATIONS":
      return {
        ...initialState,
        notifications: action.payload,
      };

    case "SET_NOTIFICATION_LOADING":
      return {
        ...initialState,
        notificationLoading: action.payload,
      };

    case "SET_RFF_ACTIVE_PLAN":
      return {
        ...initialState,
        rffPlanDetails: action.payload,
      };

    case "SET_RFF_COUNT_DETAILS":
      return {
        ...initialState,
        rffCountDetails: action.payload,
      };
    case "SET_RFF_CHANGE_PLAN_MODAL":
      return {
        ...initialState,
        changePlanModal: action.payload,
      };

    case "SET_RFF_NAV_TABS":
      return {
        ...initialState,
        proptechTabsAssigned: action.payload,
      };

    case "SET_ACTIVE_MAIN_TAB":
      return {
        ...initialState,
        activeMainTab: action.payload,
      };

    case "RFF_USER_RESET":
      return {
        ...initialState,
        user: null,
        notificationCheckUnread: false,
        notifications: [],
        notificationLoading: false,
        rffPlanDetails: null,
        rffCountDetails: null,
        changePlanModal: false,
        proptechTabsAssigned: [],
        activeMainTab: null,
      };

    default:
      return initialState;
  }
}
