import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import { useSelector } from "react-redux";
import HeaderRight from "../../components/HeaderRight";
import OverviewTab from "../../components/ProptechDetails/OverviewTab";
import leftArowIcon from "../../../assets/Icons/ic-right-arrow.svg";
import TopBlock from "../../components/ProptechDetails/TopBlock";
import "./style.scss";
import MarketInsightTab from "../../components/ProptechDetails/MarketInsightTab";
import CompanyProfileTab from "../../components/ProptechDetails/CompanyProfileTab";
import {
  getProptechDetailsAPI,
  getOverviewAPI,
  getCompanyProfileAPI,
  getDesignReviewAPI,
  getMarketInsightsAPI,
  getOfferingAPI,
  getTrackRecordAPI,
  getKeyAttributesData,
  getDeployAndUseAPI,
} from "../../utils/middleware/ProptechDetails";
import Loader from "../../../components/Loading";
import { proptchDetailsTab, proptechDetailsArray } from "../../constants";
import { showMessage } from "../../../utils/utility/snackbar";
import { MessageType } from "../../../constants/General";
import { CHANGES_MESSAGE } from "../../constants/index";
import OfferingTab from "../../components/ProptechDetails/OfferingTab";
import TrackRecordTab from "../../components/ProptechDetails/TrackRecordTab";
import DeployAndUse from "../../components/ProptechDetails/DeployAndUse";
import DesignReview from "../../components/ProptechDetails/DesignReviewTab";
import KeyAttributes from "../../components/ProptechDetails/KeyAttributes";
import { dispatch } from "../../../utils/utility/store";
import { Actions } from "../../../store/actions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProptechDetail() {
  // Store
  const rffProptechDetails = useSelector((state) => state.rffProptechDetails);
  const { proptechTabsAssigned, activeMainTab } = useSelector(
    (state) => state.rffUser
  );

  // State
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const navigate = useNavigate();

  // API call for Propech Details
  const getProptechDetails = async () => {
    if (
      rffProptechDetails.apiTimeout.proptechDetails ||
      !rffProptechDetails.proptechDetails ||
      pathName[pathName.length - 1] !== rffProptechDetails.id
    ) {
      setIsLoading(true);
      await getProptechDetailsAPI(pathName[pathName.length - 1])
        .then((res) => {
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
      await getKeyAttributesData();
    }
  };

  useEffect(() => {
    const handleAfterPrint = () => {
      // Hide the printable content after printing
      document
        .getElementById("place-proptech-details")
        .classList.remove("printable-content");
    };

    // Listen for before and after print events
    window.addEventListener("afterprint", handleAfterPrint);

    return () => {
      // Clean up event listeners
      window.removeEventListener("afterprint", handleAfterPrint);
    };
  }, []);

  useEffect(() => {
    if (pathName) {
      getProptechDetails();
      setValue(0);
      setActiveTabData(0, pathName[pathName.length - 1]);
    }
    if (activeMainTab === null) {
      dispatch(
        Actions.RFFUser.SetActiveMainTab,
        location?.state?.activeTab || 1
      );
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle tab change
  function setActiveTabData(tab, id) {
    switch (tab) {
      case 0:
        getOverviewAPI(id, setIsLoading);
        break;

      case 1:
        getOfferingAPI(id, setIsLoading);
        break;

      case 2:
        getTrackRecordAPI(id, setIsLoading);
        break;

      case 3:
        getMarketInsightsAPI(id, setIsLoading);
        break;

      case 4:
        getDeployAndUseAPI(id, setIsLoading);
        break;

      case 5:
        getDesignReviewAPI(id, setIsLoading);
        break;

      case 6:
        getCompanyProfileAPI(id, setIsLoading);
        break;

      default:
        getOverviewAPI(id, setIsLoading);
        break;
    }
  }

  const handleChange = (event, newValue) => {
    let isAllowed = proptechTabsAssigned.some(
      (i) =>
        i.navigation === activeMainTab && i.proptechTabs?.includes(newValue + 1)
    );
    if (isAllowed) {
      setValue(newValue);
      if (
        rffProptechDetails.apiTimeout[proptchDetailsTab[newValue]] ||
        !rffProptechDetails[proptechDetailsArray[newValue]]
      ) {
        setIsLoading(true);
        setActiveTabData(newValue, pathName[pathName.length - 1]);
      }
    } else {
      showMessage(CHANGES_MESSAGE.upgradePlan, MessageType.Error);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div
        id="place-proptech-details"
        className="no-print h-screen w-screen proptech-detail-wrapper-place"
      >
        <div className="grow bg-[#F7F7F7]">
          <div className="navigation-top-place">
            <div className="btn-wrapper">
              <a
                onClick={() => handleBackClick()}
                className="icon-btn left-arrow cursor-pointer"
                href={() => false}
              >
                {" "}
                <img src={leftArowIcon} alt="Icon" />
                Back
              </a>
            </div>
            <div className="right-nav-place">
              <HeaderRight />
            </div>
          </div>
          <TopBlock />
        </div>
        <div className="protech-detail-inner-place">
          <div className="tab-wrapper-place">
            <Box sx={{ width: "100%" }}>
              <Box className="tab-heading-wrapper md:sticky lg:top-[55px] xlg:top-[60px] z-10">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="proptech tabs"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                >
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Offering" {...a11yProps(1)} />
                  <Tab label="Track record" {...a11yProps(2)} />
                  <Tab label="Market insights" {...a11yProps(3)} />
                  <Tab label="Deploy + use" {...a11yProps(4)} />
                  <Tab label="Design review" {...a11yProps(5)} />
                  <Tab label="Company profile" {...a11yProps(6)} />
                </Tabs>
              </Box>
              <div className="relative md:flex md:items-start">
                <div className="tab-panels-place">
                  <TabPanel value={value} index={0}>
                    <OverviewTab />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <OfferingTab />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <TrackRecordTab />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <MarketInsightTab />
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    <DeployAndUse />
                  </TabPanel>
                  <TabPanel value={value} index={5}>
                    <DesignReview />
                  </TabPanel>
                  <TabPanel value={value} index={6}>
                    <CompanyProfileTab />
                  </TabPanel>
                </div>
                <KeyAttributes />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProptechDetail;
