export function marketTabRFFDTO(data) {
  return {
    id: data?.id || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    highDemand:
      data?.highDemand || data?.highDemand === 0 ? data?.highDemand : null,
    costToScale:
      data?.costToScale || data?.costToScale === 0 ? data?.costToScale : null,
    competition:
      data?.competition || data?.competition === 0 ? data?.competition : null,
    marketSize:
      data?.marketSize || data?.marketSize === 0 ? data?.marketSize : null,
    currentPipeline:
      data?.currentPipeline || data?.currentPipeline === 0
        ? data?.currentPipeline
        : null,
    geographyAgnostic:
      data?.geographyAgnostic || data?.geographyAgnostic === 0
        ? data?.geographyAgnostic
        : null,
    proptechCompanyDetails: {
      marketOpportunityAdmin:
        data?.proptechCompanyDetails?.marketOpportunityAdmin || "-",
    },
    proptechAnalysisChargingModel1: {
      id: data?.proptechAnalysisChargingModel1?.id || 0,
      icon: data?.proptechAnalysisChargingModel1?.icon || null,
      name: data?.proptechAnalysisChargingModel1?.name || "-",
    },
    proptechAnalysisChargingModel2: {
      id: data?.proptechAnalysisChargingModel2?.id || 0,
      icon: data?.proptechAnalysisChargingModel2?.icon || null,
      name: data?.proptechAnalysisChargingModel2?.name || "-",
    },
    proptechAnalysisChargingModel3: {
      id: data?.proptechAnalysisChargingModel3?.id || 0,
      icon: data?.proptechAnalysisChargingModel3?.icon || null,
      name: data?.proptechAnalysisChargingModel3?.name || "-",
    },
    proptechAnalysisTechBuyer1: {
      id: data?.proptechAnalysisTechBuyer1?.id || 0,
      icon: data?.proptechAnalysisTechBuyer1?.icon || null,
      name: data?.proptechAnalysisTechBuyer1?.name || "-",
    },
    proptechAnalysisTechBuyer2: {
      id: data?.proptechAnalysisTechBuyer2?.id || 0,
      icon: data?.proptechAnalysisTechBuyer2?.icon || null,
      name: data?.proptechAnalysisTechBuyer2?.name || "-",
    },
    proptechAnalysisTechBuyer3: {
      id: data?.proptechAnalysisTechBuyer3?.id || 0,
      icon: data?.proptechAnalysisTechBuyer3?.icon || null,
      name: data?.proptechAnalysisTechBuyer3?.name || "-",
    },
    proptechAnalysisValueKPI1: {
      id: data?.proptechAnalysisValueKPI1?.id || 0,
      icon: data?.proptechAnalysisValueKPI1?.icon || null,
      name: data?.proptechAnalysisValueKPI1?.name || "-",
    },
    proptechAnalysisValueKPI2: {
      id: data?.proptechAnalysisValueKPI2?.id || 0,
      icon: data?.proptechAnalysisValueKPI2?.icon || null,
      name: data?.proptechAnalysisValueKPI2?.name || "-",
    },
    proptechAnalysisValueKPI3: {
      id: data?.proptechAnalysisValueKPI3?.id || 0,
      icon: data?.proptechAnalysisValueKPI3?.icon || null,
      name: data?.proptechAnalysisValueKPI3?.name || "-",
    },
    placeRadars: data?.proptechCompanyDetails?.proptechRadarValues,
  };
}
