import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

// Component for email field share modal in rff portal
export default function EmailField(props) {
	const { onChange, options } = props;

	const TagStyle = {
		fontFamily: "Poppins",
		fontSize: "12px",
		lineHeight: "18px",
		color: "#0f2e29",
		borderColor: "#0f2e29",
	};

	const inputStyle = {
		marginBottom: "30px",
	};

	return (
		<Autocomplete
			sx={inputStyle}
			filterSelectedOptions
			multiple
			id="tags-outlined"
			options={options}
			getOptionLabel={(option) => option?.email}
			onChange={onChange}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip
						variant="outlined"
						style={TagStyle}
						label={option?.email}
						{...getTagProps({ index })}
					/>
				))
			}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Select email address"
					disabled
					sx={{
						caretColor: "transparent",
					}}
				/>
			)}
		/>
	);
}
