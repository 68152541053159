import React from "react";
import { useSelector } from "react-redux";
import { KeySpanLogo, VerticalLogoWithHeading } from "../../Label";
import "./style.scss";

export default function MarketInsightTab() {
  // Store
  const { marketInsightsDetails } = useSelector(
    (state) => state.rffProptechDetails
  );
  const { id } = useSelector((state) => state.publicProptechUser);

  return (
    <>
      {marketInsightsDetails ? (
        <div
          className={`tabpanel-wrapper-place market-insight-tab ${
            id && "!max-w-full"
          }`}
        >
          <div className="market-overview-place">
            <div className="item-market">
              {/* Trends block */}
              <div className="item-inner">
                <div className="flex items-start justify-center">
                  <h2 className="pr-4">Trends</h2>
                </div>
                <div className="item-inner-logos">
                  <div className="flex">
                    <KeySpanLogo
                      logo={marketInsightsDetails?.proptechAnalysisTrend1?.icon}
                      text={marketInsightsDetails?.proptechAnalysisTrend1?.name}
                    />
                    <KeySpanLogo
                      logo={marketInsightsDetails?.proptechAnalysisTrend2?.icon}
                      text={marketInsightsDetails?.proptechAnalysisTrend2?.name}
                    />
                    <KeySpanLogo
                      logo={marketInsightsDetails?.proptechAnalysisTrend3?.icon}
                      text={marketInsightsDetails?.proptechAnalysisTrend3?.name}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Predictions block */}
            <div className="item-market">
              <div className="item-inner">
                <div className="flex items-start justify-center">
                  <h2 className="pr-4">Predictions</h2>
                </div>
                <div className="item-inner-logos">
                  <div className="flex">
                    <KeySpanLogo
                      logo={
                        marketInsightsDetails?.proptechAnalysisPrediction1?.icon
                      }
                      text={
                        marketInsightsDetails?.proptechAnalysisPrediction1?.name
                      }
                    />
                    <KeySpanLogo
                      logo={
                        marketInsightsDetails?.proptechAnalysisPrediction2?.icon
                      }
                      text={
                        marketInsightsDetails?.proptechAnalysisPrediction2?.name
                      }
                    />
                    <KeySpanLogo
                      logo={
                        marketInsightsDetails?.proptechAnalysisPrediction3?.icon
                      }
                      text={
                        marketInsightsDetails?.proptechAnalysisPrediction3?.name
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Growth block */}
            <div className="item-market">
              <div className="item-inner">
                <div className="flex items-start justify-center">
                  <h2 className="pr-4">Growth</h2>
                </div>
                <div className="item-inner-logos">
                  <div className="flex">
                    <KeySpanLogo
                      logo={
                        marketInsightsDetails?.proptechAnalysisGrowth1?.icon
                      }
                      text={
                        marketInsightsDetails?.proptechAnalysisGrowth1?.name
                      }
                    />
                    <KeySpanLogo
                      logo={
                        marketInsightsDetails?.proptechAnalysisGrowth2?.icon
                      }
                      text={
                        marketInsightsDetails?.proptechAnalysisGrowth2?.name
                      }
                    />
                    <KeySpanLogo
                      logo={
                        marketInsightsDetails?.proptechAnalysisGrowth3?.icon
                      }
                      text={
                        marketInsightsDetails?.proptechAnalysisGrowth3?.name
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Where the market is going block */}
          <div className="market-growth-insights">
            <div className="flex items-start">
              <h2 className="pr-4">Where the market is going</h2>
            </div>
            <p>{marketInsightsDetails?.futureSynopsis}</p>
            <div className="growth-info">
              <div className="growth-item">
                <h3>{marketInsightsDetails?.future1Heading}</h3>
                <p>{marketInsightsDetails?.future1}</p>
              </div>
              <div className="growth-item">
                <h3>{marketInsightsDetails?.future2Heading}</h3>
                <p>{marketInsightsDetails?.future2}</p>
              </div>
              <div className="growth-item">
                <h3>{marketInsightsDetails?.future3Heading}</h3>
                <p>{marketInsightsDetails?.future3}</p>
              </div>
            </div>
          </div>
          {/* Key metrics block */}
          <div className="key-metrics">
            <div className="flex items-start">
              <h2 className="pr-4">Key metrics</h2>
            </div>
            <div className="metrics-wrapper">
              <div className="metrics-item">
                <span>{marketInsightsDetails?.stat1Type}</span>
                <p>{marketInsightsDetails?.stat1}</p>
              </div>
              <div className="metrics-item">
                <span>{marketInsightsDetails?.stat2Type}</span>
                <p>{marketInsightsDetails?.stat2}</p>
              </div>
              <div className="metrics-item">
                <span>{marketInsightsDetails?.stat3Type}</span>
                <p>{marketInsightsDetails?.stat3}</p>
              </div>
              <div className="metrics-item">
                <span>{marketInsightsDetails?.stat4Type}</span>
                <p>{marketInsightsDetails?.stat4}</p>
              </div>
            </div>
          </div>
          {/* Need to know block */}
          <div className="market-growth-1 information-growth">
            <div className="flex items-start">
              <h2 className="pr-4">Need to know</h2>
            </div>
            <div className="growth-info">
              <VerticalLogoWithHeading
                logo={
                  marketInsightsDetails?.proptechAnalysisNeedToKnow1Heading
                    ?.icon
                }
                heading={
                  marketInsightsDetails?.proptechAnalysisNeedToKnow1Heading
                    ?.name
                }
                text={marketInsightsDetails?.needToKnow1Text}
              />
              <VerticalLogoWithHeading
                logo={
                  marketInsightsDetails?.proptechAnalysisNeedToKnow2Heading
                    ?.icon
                }
                heading={
                  marketInsightsDetails?.proptechAnalysisNeedToKnow2Heading
                    ?.name
                }
                text={marketInsightsDetails?.needToKnow2Text}
              />
              <VerticalLogoWithHeading
                logo={
                  marketInsightsDetails?.proptechAnalysisNeedToKnow3Heading
                    ?.icon
                }
                heading={
                  marketInsightsDetails?.proptechAnalysisNeedToKnow3Heading
                    ?.name
                }
                text={marketInsightsDetails?.needToKnow3Text}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
