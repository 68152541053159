import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import Axios from "axios";
import LeftLogo from "../../../components/LeftLogo";
import PTInput from "../../../components/GenericComponents/PTInput";
import PTButton from "../../../components/GenericComponents/PTButton";
import { RFF_LOGIN_API } from "../../../constants/APIs";
import { Store } from "../../../store/configureStore";
import { Actions } from "../../../store/actions";
import {
	getRememberIdRFF,
	setRememberIdRFF,
	setRFFAccessToken,
	setRFFLocalStorageUserData,
} from "../../../utils/session";
import { handleError, handleSuccess } from "../../../utils/utility/snackbar";
import { startLoading, stopLoading } from "../../../utils/utility/loader";
import { FORM_FIELDS, WELCOME_POP_UP_COUNT } from "../../constants";
import { configs } from "../../../constants/Configs";
import { navTabsTBDTO } from "../../utils/dtos/navigationTabsDTO";

// RFF login page
function Login() {
	const rememberId = getRememberIdRFF();
	const [loginError, setLoginError] = useState(false);
	const [rememberMe, setRememberMe] = useState(!!rememberId);
	const initialValues = {
		email: rememberId,
		password: "",
		captcha: "",
	};

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: Yup.object({
			email: Yup.string()
				.nullable()
				.email("This email is not a valid format.")
				.required("Required"),
			password: Yup.string().required("Required"),
			// .min(6, 'Password is too short - should be 6 chars minimum.')
			// .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, 'Password has contain minimum 6 characters (includes 1 Upper, 1 Special, 1 Numarical)'),
			captcha: Yup.string().required("Required"),
		}),
		onSubmit: (values, { resetForm }) => {
			userLogin(
				{
					email: values.email,
					password: values.password,
				},
				resetForm
			);
		},
	});

	//Handle API call for login
	const userLogin = (userData, resetForm) => {
		startLoading();
		Axios.post(RFF_LOGIN_API, userData)
			.then((res) => {
				if (res?.data?.code === 200) {
					stopLoading();
					Store.dispatch({ type: Actions.RFFAuth.RFFLoginSuccess });
					Store.dispatch({
						type: Actions.RFFUser.RFFUserSet,
						payload: res.data.data,
					});
					Store.dispatch({
						type: Actions.RFFUser.SetNavTabs,
						payload: navTabsTBDTO(res?.data?.data?.proptechTabsAssigned || []),
					});
					Store.dispatch({
						type: Actions.RFFProptechs.RFFShowModal,
						payload: res?.data?.data?.loginCount <= WELCOME_POP_UP_COUNT,
					});
					setRFFLocalStorageUserData(res.data.data);
					setRFFAccessToken(res?.data?.data?.token);
					resetForm({ values: "" });
					handleSuccess(res);
					navigate("/dashboard", { replace: true });
					rememberMe
						? setRememberIdRFF(res?.data?.data?.email)
						: setRememberIdRFF("");
				} else {
					stopLoading();
					Store.dispatch({ type: Actions.RFFAuth.RFFLoginFailed });
					Store.dispatch({ type: Actions.RFFUser.RFFUserRemove });
					setLoginError(res?.response?.data?.message);
					handleError(res?.data);
				}
			})
			.catch((error) => {
				stopLoading();
				Store.dispatch({ type: Actions.RFFAuth.RFFLoginFailed });
				Store.dispatch({ type: Actions.RFFUser.RFFUserRemove });
				setLoginError(error?.response?.data?.message);
				// handleError(error)
			});
	};

	return (
		<div className="w-screen flex proptech-layout-wrapper flex-wrap">
			<div className="w-full md:w-3/5">
				<LeftLogo />
			</div>
			<div className="md:min-h-screen w-full md:w-2/5 items-center inline-flex relative">
				<div className="wrapper-container">
					<div className="justify-start flex-col">
						<div className="text-wrapper">
							<h2>Log in</h2>
						</div>
						{loginError ? (
							<div className="danger-login">
								<span>{loginError}</span>
							</div>
						) : null}
						<form onSubmit={formik.handleSubmit}>
							<div className="form-wrapper">
								<div
									className={
										"form-group " +
										(formik.touched.email && formik.errors.email
											? "error-parent"
											: "") +
										(!formik.errors.email && formik.values.email
											? "success-attempt"
											: "")
									}
								>
									<PTInput
										id="email"
										name="email"
										placeholder="Email"
										label="Email"
										fullWidth
										className="form-control"
										handleOnChangeEvent={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
									/>
									{formik.touched.email && formik.errors.email && (
										<span className="error-txt">{formik.errors.email}</span>
									)}
								</div>
								<div
									className={
										"form-group " +
										(formik.touched.password && formik.errors.password
											? "error-parent"
											: "") +
										(!formik.errors.password && formik.values.password
											? "success-attempt"
											: "")
									}
								>
									<PTInput
										id="password"
										name="password"
										type="password"
										placeholder="Password"
										label="Password"
										fullWidth
										className="form-control"
										handleOnChangeEvent={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.password}
									/>
									{formik.touched.password && formik.errors.password && (
										<span className="error-txt">{formik.errors.password}</span>
									)}
								</div>
								<div className="w-full flex items-center mt-6 mb-6">
									<Checkbox
										className="!p-0 place-form-checkbox"
										id="remember"
										checked={rememberMe}
										onChange={(e) => setRememberMe(e.target.checked)}
									/>
									<label
										htmlFor="remember"
										className="flex flex-grow justify-between items-center"
									>
										<span className="text-sm leading-3 not-italic pl-1 font-['Poppins'] text-[#0f2e29]">
											{FORM_FIELDS.rememerMe}
										</span>
										<span
											className="font-semibold text-sm leading-3 text-right not-italic font-['Poppins'] text-[#0f2e29] cursor-pointer"
											onClick={() => navigate("/forgotpassword")}
										>
											{FORM_FIELDS.forgotPassword}
										</span>
									</label>
								</div>
								<div className="g-recaptcha form-group error-parent">
									<ReCAPTCHA
										sitekey={configs.siteKey}
										name="captcha"
										onErrored={(e) => formik.setFieldValue("captcha", e)}
										onExpired={(e) => formik.setFieldValue("captcha", e)}
										onChange={(e) => formik.setFieldValue("captcha", e)}
									/>
									{formik.touched.captcha && formik.errors.captcha && (
										<span className="error-txt">{formik.errors.captcha}</span>
									)}
								</div>
								<PTButton
									label={"Log in"}
									type="submit"
									className="normal-case fill-blue btn"
									size="large"
								/>
							</div>
						</form>
					</div>
					<div className="not-have-account">
						<span>
							Not registered yet?{" "}
							<span
								onClick={() => navigate(`/signup`)}
								className="font-semibold cursor-pointer"
							>
								Create an account
							</span>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
