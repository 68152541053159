import { Navigate } from "react-router-dom";
import { getRFFAccessToken } from "../../../utils/session";

// For public user
const PublicRoute = ({ children }) => {
  const isLogin = getRFFAccessToken();

  return <>{!isLogin ? children : <Navigate to="/dashboard" />}</>;
};
export default PublicRoute;
