export const chartData = [
  "evolvingAssets",
  "userExperience",
  "decisionMaking",
  "smarterBuilds",
  "connectivity",
  "trackRecord",
];

// export const chartLabels = [
//   "Evolving assets",
//   ["User", "experience"],
//   ["Decision", "making"],
//   "Smarter builds",
//   "Connectivity",
//   // ["Track", "Record"],
//   ["Deal", "Closing"],
// ];

export const chartLabels = [
  "Cost",
  "Speed",
  "Scale",
  "Quality",
  "ESG",
  "Versatility",
];

export const marketChartLabels = [
  ["Unique", "Offering"],
  ["Deal", "Closing"],
  ["Integration +", " Operability"],
  ["Use case + ", "User Count"],
  ["Payback", " visibility"],
  ["Ease of setup + ", "Use"],
];

export const numbersChartLabels = [
  ["Market Leader", "Customers"],
  "New customers",
  ["Enterprise", "experience"],
  // ["Enterprise", "RFP experience"],
  // ["Multi-country / ","language customers"],
  "Global customers",
  ["Repeat", "Customers"],
  ["Tech advanced", "organization"],
];

export const designReviewChartLabels = [
  "Architecture",
  "Power",
  "Processing",
  "Communication",
  "Search",
  "Storage",
];

export const deployAndUseChartLabels = [
  ["Customer", "Support"],
  ["Flexible", "architecture"],
  "Scalability",
  "Tech agnostic",
  ["Customisable", "functionality"],
  ["New features,", "roadmap"],
];
