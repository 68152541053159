import React from "react";
import { useSelector } from "react-redux";
import { Actions } from "../../../store/actions";
import { dispatch } from "../../../utils/utility/store";
import Pagination from "../../components/Pagination";
import ProptechCard from "../../components/ProtechCard";

export default function HomeTab({ tab }) {
  // State
  const { page, size, rffProptechs } = useSelector(
    (state) => state.rffProptechs
  );

  // Set page no. in store on page change in pagination
  const handlePageChange = (v) => {
    dispatch(Actions.RFFProptechs.RFFSetPage, v);
  };

  // Set page no. and size in store on page change in pagination
  const handleRowChange = (v) => {
    dispatch(Actions.RFFProptechs.RFFSetPageSize, v);
    dispatch(Actions.RFFProptechs.RFFSetPage, 0);
  };

  return (
    <>
      <div className="no-print proptech-card-wrapper">
        {/* Proptech card */}
        {rffProptechs?.rows && rffProptechs?.rows.length
          ? rffProptechs?.rows.map((proptech) => {
              return (
                <ProptechCard
                  proptech={proptech}
                  key={proptech?.id}
                  tab={tab}
                />
              );
            })
          : null}
      </div>
      {/* Pagination */}
      <div className="flex justify-between items-center">
        {rffProptechs?.count ? (
          <span className="text-sm font-normal">All values in USD</span>
        ) : null}
        {rffProptechs?.count ? (
          <Pagination
            length={rffProptechs?.count}
            tab="Proptechs"
            page={page}
            size={size}
            handlePageChange={(v) => handlePageChange(v)}
            handleRowChange={(v) => handleRowChange(v)}
          />
        ) : null}
      </div>
    </>
  );
}
