export function companyProfileTabRFFDTO(data) {
  return {
    id: data?.id || 0,
    raiseDate: data?.raiseDate || null,
    priorRaiseDate: data?.priorRaiseDate || null,
    keyCustomer1: data?.keyCustomer1 || "-",
    keyCustomer2: data?.keyCustomer2 || "-",
    keyCustomer3: data?.keyCustomer3 || "-",
    keyCustomer1Logo: data?.keyCustomer1Logo || null,
    keyCustomer2Logo: data?.keyCustomer2Logo || null,
    keyCustomer3Logo: data?.keyCustomer3Logo || null,
    revenueRange: {
      id: data?.revenueRange?.id || 0,
      icon: data?.revenueRange?.icon || null,
      name: data?.revenueRange?.name || "-",
    },
    committed: {
      id: data?.committed?.id || 0,
      icon: data?.committed?.icon || null,
      name: data?.committed?.name || "-",
    },
    investmentRound: {
      id: data?.investmentRound?.id || 0,
      icon: data?.investmentRound?.icon || null,
      name: data?.investmentRound?.name || "-",
    },
    investmentRange: {
      id: data?.investmentRange?.id || 0,
      icon: data?.investmentRange?.icon || null,
      name: data?.investmentRange?.name || "-",
    },
    valuationRange: {
      id: data?.valuationRange?.id || 0,
      icon: data?.valuationRange?.icon || null,
      name: data?.valuationRange?.name || "-",
    },
    investmentType: {
      id: data?.investmentType?.id || 0,
      icon: data?.investmentType?.icon || null,
      name: data?.investmentType?.name || "-",
    },
    keyBizModel: {
      id: data?.keyBizModel?.id || 0,
      icon: data?.keyBizModel?.icon || null,
      name: data?.keyBizModel?.name || "-",
    },
    priorInvestmentRound: {
      id: data?.priorInvestmentRound?.id || 0,
      icon: data?.priorInvestmentRound?.icon || null,
      name: data?.priorInvestmentRound?.name || "-",
    },
    priorInvestmentRange: {
      id: data?.priorInvestmentRange?.id || 0,
      icon: data?.priorInvestmentRange?.icon || null,
      name: data?.priorInvestmentRange?.name || "-",
    },
    priorValuationRange: {
      id: data?.priorValuationRange?.id || 0,
      icon: data?.priorValuationRange?.icon || null,
      name: data?.priorValuationRange?.name || "-",
    },
    priorInvestmentType: {
      id: data?.priorInvestmentType?.id || 0,
      icon: data?.priorInvestmentType?.icon || null,
      name: data?.priorInvestmentType?.name || "-",
    },
    priorKeyBizModel: {
      id: data?.priorKeyBizModel?.id || 0,
      icon: data?.priorKeyBizModel?.icon || null,
      name: data?.priorKeyBizModel?.name || "-",
    },
    proptechAnalysisOverview: {
      id: data?.proptechAnalysisOverview?.id || 0,
      proptechAnalysisUseCase1: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.id || 0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.icon ||
          null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisUseCase1?.name || "-",
      },
      proptechAnalysisUseCase2: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.id || 0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.icon ||
          null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisUseCase2?.name || "-",
      },
      proptechAnalysisUseCase3: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.id || 0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.icon ||
          null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisUseCase3?.name || "-",
      },
      proptechAnalysisSector1: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisSector1?.id || 0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisSector1?.icon || null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisSector1?.name || "-",
      },
      proptechAnalysisSector2: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisSector2?.id || 0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisSector2?.icon || null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisSector2?.name || "-",
      },
      proptechAnalysisSector3: {
        id: data?.proptechAnalysisOverview?.proptechAnalysisSector3?.id || 0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisSector3?.icon || null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisSector3?.name || "-",
      },
      proptechAnalysisTechOffering1: {
        id:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.id ||
          0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.icon ||
          null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering1?.name ||
          "-",
      },
      proptechAnalysisTechOffering2: {
        id:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.id ||
          0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.icon ||
          null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering2?.name ||
          "-",
      },
      proptechAnalysisTechOffering3: {
        id:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.id ||
          0,
        icon:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.icon ||
          null,
        name:
          data?.proptechAnalysisOverview?.proptechAnalysisTechOffering3?.name ||
          "-",
      },
    },
    proptechAnalysisMarket: {
      id: data?.proptechAnalysisMarket?.id || 0,
      proptechAnalysisChargingModel1: {
        id:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.icon ||
          null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel1?.name ||
          "-",
      },
      proptechAnalysisChargingModel2: {
        id:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.icon ||
          null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel2?.name ||
          "-",
      },
      proptechAnalysisChargingModel3: {
        id:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.icon ||
          null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisChargingModel3?.name ||
          "-",
      },
      proptechAnalysisTechBuyer1: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.icon ||
          null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer1?.name || "-",
      },
      proptechAnalysisTechBuyer2: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.icon ||
          null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer2?.name || "-",
      },
      proptechAnalysisTechBuyer3: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.icon ||
          null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisTechBuyer3?.name || "-",
      },
      proptechAnalysisValueKPI1: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.icon || null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI1?.name || "-",
      },
      proptechAnalysisValueKPI2: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.icon || null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI2?.name || "-",
      },
      proptechAnalysisValueKPI3: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.icon || null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI3?.name || "-",
      },
      proptechAnalysisValueKPI4: {
        id: data?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.id || 0,
        icon:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.icon || null,
        name:
          data?.proptechAnalysisMarket?.proptechAnalysisValueKPI4?.name || "-",
      },
    },
    proptechAnalysisNumber: {
      id: data?.proptechAnalysisNumber?.id || 0,
      proptechAnalysisCurrentGrossMargin: {
        id:
          data?.proptechAnalysisNumber?.proptechAnalysisCurrentGrossMargin
            ?.id || 0,
        icon:
          data?.proptechAnalysisNumber?.proptechAnalysisCurrentGrossMargin
            ?.icon || null,
        name:
          data?.proptechAnalysisNumber?.proptechAnalysisCurrentGrossMargin
            ?.name || "-",
      },
      proptechAnalysisFutureGrossMargin: {
        id:
          data?.proptechAnalysisNumber?.proptechAnalysisFutureGrossMargin?.id ||
          0,
        icon:
          data?.proptechAnalysisNumber?.proptechAnalysisFutureGrossMargin
            ?.icon || null,
        name:
          data?.proptechAnalysisNumber?.proptechAnalysisFutureGrossMargin
            ?.name || "-",
      },
    },
    proptechAnalysisRaise: {
      id: data?.proptechAnalysisRaise?.id || 0,
      proptechAnalysisStyle1: {
        id: data?.proptechAnalysisRaise?.proptechAnalysisStyle1?.id || 0,
        icon: data?.proptechAnalysisRaise?.proptechAnalysisStyle1?.icon || null,
        name: data?.proptechAnalysisRaise?.proptechAnalysisStyle1?.name || "-",
      },
      proptechAnalysisTrait1: {
        id: data?.proptechAnalysisRaise?.proptechAnalysisTrait1?.id || 0,
        icon: data?.proptechAnalysisRaise?.proptechAnalysisTrait1?.icon || null,
        name: data?.proptechAnalysisRaise?.proptechAnalysisTrait1?.name || "-",
      },
      proptechAnalysisTrait2: {
        id: data?.proptechAnalysisRaise?.proptechAnalysisTrait2?.id || 0,
        icon: data?.proptechAnalysisRaise?.proptechAnalysisTrait2?.icon || null,
        name: data?.proptechAnalysisRaise?.proptechAnalysisTrait2?.name || "-",
      },
    },
    proptechAnalysisTeam: {
      id: data?.proptechAnalysisTeam?.id || 0,
      proptechAnalysisTeamSkill1: {
        id: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.id || 0,
        icon:
          data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.icon || null,
        name:
          data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill1?.name || "-",
      },
      proptechAnalysisTeamSkill2: {
        id: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.id || 0,
        icon:
          data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.icon || null,
        name:
          data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill2?.name || "-",
      },
      proptechAnalysisTeamSkill3: {
        id: data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.id || 0,
        icon:
          data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.icon || null,
        name:
          data?.proptechAnalysisTeam?.proptechAnalysisTeamSkill3?.name || "-",
      },
    },
    proptechPlaceCompanyDetails: {
      proptechContactPointVendor1: {
        id:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor1?.id ||
          0,
        icon:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor1
            ?.icon || null,
        name:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor1
            ?.name || "-",
      },
      proptechContactPointVendor2: {
        id:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor2?.id ||
          0,
        icon:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor2
            ?.icon || null,
        name:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor2
            ?.name || "-",
      },
      proptechContactPointVendor3: {
        id:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor3?.id ||
          0,
        icon:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor3
            ?.icon || null,
        name:
          data?.proptechPlaceCompanyDetails?.proptechContactPointVendor3
            ?.name || "-",
      },
      futureSynopsis: data?.proptechPlaceCompanyDetails?.futureSynopsis || "-",
      future1Heading: data?.proptechPlaceCompanyDetails?.future1Heading || "-",
      future1: data?.proptechPlaceCompanyDetails?.future1 || "-",
      future2Heading: data?.proptechPlaceCompanyDetails?.future2Heading || "-",
      future2: data?.proptechPlaceCompanyDetails?.future2 || "-",
      future3Heading: data?.proptechPlaceCompanyDetails?.future3Heading || "-",
      future3: data?.proptechPlaceCompanyDetails?.future3 || "-",
      pastProjectRange:
        data?.proptechPlaceCompanyDetails?.pastProjectRange || null,
      placeGeography: data?.proptechPlaceCompanyDetails?.placeGeography || null,
      projectScale: data?.proptechPlaceCompanyDetails?.projectScale || null,
    },
    proptechAnalysisDeployUse: {
      integrationKey1: {
        id: data?.proptechAnalysisDeployUse?.integrationKey1?.id || 0,
        icon: data?.proptechAnalysisDeployUse?.integrationKey1?.icon || null,
        name: data?.proptechAnalysisDeployUse?.integrationKey1?.name || "-",
      },
      integrationKey2: {
        id: data?.proptechAnalysisDeployUse?.integrationKey2?.id || 0,
        icon: data?.proptechAnalysisDeployUse?.integrationKey2?.icon || null,
        name: data?.proptechAnalysisDeployUse?.integrationKey2?.name || "-",
      },
      integrationKey3: {
        id: data?.proptechAnalysisDeployUse?.integrationKey3?.id || 0,
        icon: data?.proptechAnalysisDeployUse?.integrationKey3?.icon || null,
        name: data?.proptechAnalysisDeployUse?.integrationKey3?.name || "-",
      },
      proptechRisk1: {
        id: data?.proptechAnalysisDeployUse?.proptechRisk1?.id || 0,
        icon: data?.proptechAnalysisDeployUse?.proptechRisk1?.icon || null,
        name: data?.proptechAnalysisDeployUse?.proptechRisk1?.name || "-",
      },
      proptechRisk2: {
        id: data?.proptechAnalysisDeployUse?.proptechRisk2?.id || 0,
        icon: data?.proptechAnalysisDeployUse?.proptechRisk2?.icon || null,
        name: data?.proptechAnalysisDeployUse?.proptechRisk2?.name || "-",
      },
      proptechRisk3: {
        id: data?.proptechAnalysisDeployUse?.proptechRisk3?.id || 0,
        icon: data?.proptechAnalysisDeployUse?.proptechRisk3?.icon || null,
        name: data?.proptechAnalysisDeployUse?.proptechRisk3?.name || "-",
      },
      riskText1: data?.proptechAnalysisDeployUse?.riskText1 || "-",
      riskText2: data?.proptechAnalysisDeployUse?.riskText2 || "-",
      riskText3: data?.proptechAnalysisDeployUse?.riskText3 || "-",
      integrationText1:
        data?.proptechAnalysisDeployUse?.integrationText1 || "-",
      integrationText2:
        data?.proptechAnalysisDeployUse?.integrationText2 || "-",
      integrationText3:
        data?.proptechAnalysisDeployUse?.integrationText3 || "-",
    },
    proptechRadarValues: data?.proptechRadarValues?.map((item) => ({
      value: item.value,
      name: item?.placeRadarPoint?.radarName,
    })),
    proptechAnalysisCompanyProfile: {
      id: data?.proptechAnalysisCompanyProfile?.id || 0,
      problemSynopsis:
        data?.proptechAnalysisCompanyProfile?.problemSynopsis || "-",
      problem1Heading:
        data?.proptechAnalysisCompanyProfile?.problem1Heading || "-",
      problem1Text: data?.proptechAnalysisCompanyProfile?.problem1Text || "-",
      problem2Heading:
        data?.proptechAnalysisCompanyProfile?.problem2Heading || "-",
      problem2Text: data?.proptechAnalysisCompanyProfile?.problem2Text || "-",
      problem3Heading:
        data?.proptechAnalysisCompanyProfile?.problem3Heading || "-",
      problem3Text: data?.proptechAnalysisCompanyProfile?.problem3Text || "-",
      solutionSynopsis:
        data?.proptechAnalysisCompanyProfile?.solutionSynopsis || "-",
      solution1Heading:
        data?.proptechAnalysisCompanyProfile?.solution1Heading || "-",
      solution1Text: data?.proptechAnalysisCompanyProfile?.solution1Text || "-",
      solution2Heading:
        data?.proptechAnalysisCompanyProfile?.solution2Heading || "-",
      solution2Text: data?.proptechAnalysisCompanyProfile?.solution2Text || "-",
      solution3Heading:
        data?.proptechAnalysisCompanyProfile?.solution3Heading || "-",
      solution3Text: data?.proptechAnalysisCompanyProfile?.solution3Text || "-",
      bizModel1Heading:
        data?.proptechAnalysisCompanyProfile?.bizModel1Heading || "-",
      bizModel1Text: data?.proptechAnalysisCompanyProfile?.bizModel1Text || "-",
      bizModel2Heading:
        data?.proptechAnalysisCompanyProfile?.bizModel2Heading || "-",
      bizModel2Text: data?.proptechAnalysisCompanyProfile?.bizModel2Text || "-",
      bizModel3Heading:
        data?.proptechAnalysisCompanyProfile?.bizModel3Heading || "-",
      bizModel3Text: data?.proptechAnalysisCompanyProfile?.bizModel3Text || "-",
      goToMarket1Heading:
        data?.proptechAnalysisCompanyProfile?.goToMarket1Heading || "-",
      goToMarket1Text:
        data?.proptechAnalysisCompanyProfile?.goToMarket1Text || "-",
      goToMarket2Heading:
        data?.proptechAnalysisCompanyProfile?.goToMarket2Heading || "-",
      goToMarket2Text:
        data?.proptechAnalysisCompanyProfile?.goToMarket2Text || "-",
      goToMarket3Heading:
        data?.proptechAnalysisCompanyProfile?.goToMarket3Heading || "-",
      goToMarket3Text:
        data?.proptechAnalysisCompanyProfile?.goToMarket3Text || "-",
      customerBreakdown1Text:
        data?.proptechAnalysisCompanyProfile?.customerBreakdown1Text || null,
      customerBreakdown2Text:
        data?.proptechAnalysisCompanyProfile?.customerBreakdown2Text || null,
      customerBreakdown3Text:
        data?.proptechAnalysisCompanyProfile?.customerBreakdown3Text || null,
      noBreakdown:
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text ||
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text ||
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text ||
        true,
      customerKeyPoint1Text:
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text || null,
      customerKeyPoint2Text:
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text || null,
      customerKeyPoint3Text:
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text || null,
      noKeyPoint:
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint1Text ||
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint2Text ||
        data?.proptechAnalysisCompanyProfile?.customerKeyPoint3Text ||
        true,
      currentTraction2:
        data?.proptechAnalysisCompanyProfile?.currentTraction2 || "-",
      futureMetric1Text:
        data?.proptechAnalysisCompanyProfile?.futureMetric1Text || "-",
      futureMetric2Text:
        data?.proptechAnalysisCompanyProfile?.futureMetric2Text || "-",
      futureMetric3Text:
        data?.proptechAnalysisCompanyProfile?.futureMetric3Text || "-",
      valueKPI1Text: data?.proptechAnalysisCompanyProfile?.valueKPI1Text || "-",
      valueKPI2Text: data?.proptechAnalysisCompanyProfile?.valueKPI2Text || "-",
      valueKPI3Text: data?.proptechAnalysisCompanyProfile?.valueKPI3Text || "-",
      valueKPI4Text: data?.proptechAnalysisCompanyProfile?.valueKPI4Text || "-",
      whyGoodFit1Text:
        data?.proptechAnalysisCompanyProfile?.whyGoodFit1Text || "-",
      whyGoodFit2Text:
        data?.proptechAnalysisCompanyProfile?.whyGoodFit2Text || "-",
      whyGoodFit3Text:
        data?.proptechAnalysisCompanyProfile?.whyGoodFit3Text || "-",
      defensibilityTiming1Text:
        data?.proptechAnalysisCompanyProfile?.defensibilityTiming1Text || "-",
      defensibilityTiming2Text:
        data?.proptechAnalysisCompanyProfile?.defensibilityTiming2Text || "-",
      defensibilityTiming3Text:
        data?.proptechAnalysisCompanyProfile?.defensibilityTiming3Text || "-",
      skill1Text: data?.proptechAnalysisCompanyProfile?.skill1Text || "-",
      skill2Text: data?.proptechAnalysisCompanyProfile?.skill2Text || "-",
      skill3Text: data?.proptechAnalysisCompanyProfile?.skill3Text || "-",
      proptechAnalysisLifecycle1: {
        id:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1
            ?.id || 0,
        icon:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle1
            ?.name || "-",
      },
      proptechAnalysisLifecycle2: {
        id:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2
            ?.id || 0,
        icon:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle2
            ?.name || "-",
      },
      proptechAnalysisLifecycle3: {
        id:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3
            ?.id || 0,
        icon:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisLifecycle3
            ?.name || "-",
      },
      keyBizModelTraction2Stat: {
        id:
          data?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat?.id ||
          0,
        icon:
          data?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.keyBizModelTraction2Stat
            ?.name || "-",
      },
      keyBizModelFutureMetric1: {
        id:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric1?.id ||
          0,
        icon:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric1
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric1
            ?.name || "-",
      },
      keyBizModelFutureMetric2: {
        id:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric2?.id ||
          0,
        icon:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric2
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric2
            ?.name || "-",
      },
      keyBizModelFutureMetric3: {
        id:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric3?.id ||
          0,
        icon:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric3
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.keyBizModelFutureMetric3
            ?.name || "-",
      },
      proptechAnalysisDefTiming1: {
        id:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming1
            ?.id || 0,
        icon:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming1
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming1
            ?.name || "-",
      },
      proptechAnalysisDefTiming2: {
        id:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming2
            ?.id || 0,
        icon:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming2
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming2
            ?.name || "-",
      },
      proptechAnalysisDefTiming3: {
        id:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming3
            ?.id || 0,
        icon:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming3
            ?.icon || null,
        name:
          data?.proptechAnalysisCompanyProfile?.proptechAnalysisDefTiming3
            ?.name || "-",
      },
    },
  };
}
