import React, { useState, useEffect } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import HideImageOutlinedIcon from "@mui/icons-material/HideImageOutlined";
import closeIcon from "../../../assets/Icons/ic-cross.svg";
import mapIcon from "../../../assets/Icons/ic-map-rff.svg";
import techofferingIcon from "../../../assets/Icons/techOffering1.svg";
import employeeIcon from "../../../assets/Icons/ic-employee.svg";
import paymentIcon from "../../../assets/Icons/ic-payment-place.svg";
import leftArowIcon from "../../../assets/Icons/ic-right-arrow.svg";
import communicationIcon from "../../../assets/Icons/ic-communication.svg";
import communicationIcon2 from "../../../assets/Icons/ic-communication-filled.svg";
import shareIcon from "../../../assets/Icons/ic-share.svg";
import starIcon from "../../../assets/Icons/ic-star.svg";
import starIconActive from "../../../assets/Icons/StarIconActive.svg";
import { KeyFeatureLabel } from "../../components/Label";
import Chart from "../Chart";
import { chartData, chartLabels } from "../../utils/chartData";
import "../../pages/Dashboard/dashboard.scss";
import {
  setWatchlistStatus,
  updateNote,
} from "../../utils/middleware/Dashboard";
import { useSelector } from "react-redux";
import PTInput from "../../../components/GenericComponents/PTInput";
import PTButton from "../../../components/GenericComponents/PTButton";
import { getRFFLocalStorageUserData } from "../../../utils/session";
import ForYouTag from "../ForYouTag";
import { PROPTECHCARD } from "../../constants";
import ShareModal from "../ShareModal";
import { getActiveUsers } from "../../utils/middleware/Dropdowns";
import { dispatch } from "../../../utils/utility/store";
import { Actions } from "../../../store/actions";
import { activeUsersDTO } from "../../utils/dtos/activeUsersList";
import { TooltipText } from "../../../constants/General";
import { stringAvatar } from "../../../utils/utility/utility";

// Proptech card for rff dashboard
export default function ProptechCard(props) {
  //  ***** props *****
  const { proptech, className, tab } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  // state
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [noteData, setNoteData] = useState("");
  const [shareMessage, setShareMessage] = useState("");
  const [userData, setUserData] = useState({});
  const [activeUsers, setActiveUsers] = useState([]);
  const navigate = useNavigate();

  // Store
  const rffProptechs = useSelector((state) => state.rffProptechs);
  const rffForYou = useSelector((state) => state.rffForYou);
  const { activeMainTab } = useSelector((state) => state.rffUser);

  const handleNoteCloseModal = () => {
    setOpenNoteModal(false);
    setNoteData(proptech?.rffProptechNote[0]?.note);
  };

  const handleShareCloseModal = () => {
    setOpenShareModal(false);
    setShareMessage("");
    if (activeUsers?.length) {
      dispatch(
        Actions.RFFProptechs.RFFSetActiveUsers,
        activeUsersDTO(activeUsers)
      );
    }
  };

  // Getting user data from local storage and set note data
  useEffect(() => {
    setUserData(getRFFLocalStorageUserData());
    if (proptech?.rffProptechNote.length) {
      setNoteData(proptech?.rffProptechNote[0]?.note);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateToDetails = () => {
    dispatch(
      Actions.RFFUser.SetActiveMainTab,
      proptech?.rffProptechFavorite[0]?.navigation?.id || activeMainTab
    );
    navigate(`/proptech/${proptech?.id}`, {
      state: {
        activeTab:
          proptech?.rffProptechFavorite[0]?.navigation?.id || activeMainTab,
      },
    });
  };

  const handleNoteModal = async () => {
    setOpenNoteModal(true);
  };

  const handleShareModal = async () => {
    setOpenShareModal(true);
    if (rffProptechs.rffActiveUsers?.length) {
      setActiveUsers(rffProptechs.rffActiveUsers);
    } else {
      getActiveUsers().then((res) => {
        setActiveUsers(res);
      });
    }
  };

  const handleNoteSubmit = () => {
    updateNote(
      proptech?.id,
      noteData.trim(),
      tab,
      rffProptechs,
      rffForYou
    ).then(() => {
      setOpenNoteModal(false);
    });
  };

  return (
    <>
      {proptech && (
        <div className={className ? className : ""}>
          {/* For you tab card label eith avatar*/}
          {className === "for-you-card" && (
            <div className="top-fit flex justify-between items-center">
              <div className="top-fit-left flex items-center">
                <span>{PROPTECHCARD?.goodFit}</span>
                {/* {proptech?.rffProptechForYou[0]?.rffProptechForYouTag[0]
                  .length ? (
                  <ForYouTag
                    tagsList={
                      proptech?.rffProptechForYou[0]?.rffProptechForYouTag[0]
                    }
                  />
                ) : null} */}
                {proptech?.proptechAnalysisNumber?.currentNoOfCountries ? (
                  <ForYouTag
                    tagname={
                      "< " +
                      proptech?.proptechAnalysisNumber?.currentNoOfCountries +
                      " countries"
                    }
                  />
                ) : null}
                {proptech?.proptechAnalysisNumber?.currentNoOfCustomers ? (
                  <ForYouTag
                    tagname={
                      proptech?.proptechAnalysisNumber?.currentNoOfCustomers +
                      " customers"
                    }
                  />
                ) : null}
              </div>
              <div className="top-fit-right">
                <span>{PROPTECHCARD?.curated}</span>
                <IconButton
                  size="small"
                  sx={{ ml: 1 }}
                  className="profile-user"
                >
                  <Avatar
                    sx={{ width: 32, height: 32 }}
                    {...stringAvatar(userData?.name)}
                  ></Avatar>
                </IconButton>
              </div>
            </div>
          )}
          <div className="proptech-card flex">
            {/* Note, share modal and set watchlist wrapper */}
            <div className="icon-wrapper" onClick={(e) => e.stopPropagation()}>
              <Tooltip
                title={TooltipText.comment}
                arrow
                PopperProps={{ style: { zIndex: 1 } }}
              >
                <IconButton onClick={() => handleNoteModal()}>
                  <img
                    src={
                      proptech?.rffProptechNote[0]?.note?.trim().length > 0
                        ? communicationIcon2
                        : communicationIcon
                    }
                    alt="Icon"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={TooltipText.share}
                arrow
                PopperProps={{ style: { zIndex: 1 } }}
              >
                <IconButton onClick={() => handleShareModal()}>
                  <img src={shareIcon} alt="Icon" />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={TooltipText.watchlist}
                placement="bottom-start"
                arrow
                PopperProps={{ style: { zIndex: 1 } }}
              >
                <IconButton
                  onClick={() =>
                    setWatchlistStatus(
                      proptech?.id,
                      tab,
                      !!proptech?.rffProptechFavorite.length,
                      rffProptechs,
                      rffForYou,
                      proptech?.rffProptechFavorite[0]?.navigation?.id ||
                        activeMainTab
                    )
                  }
                >
                  <img
                    src={
                      proptech?.rffProptechFavorite.length
                        ? starIconActive
                        : starIcon
                    }
                    alt="Icon"
                  />
                </IconButton>
              </Tooltip>
            </div>
            {/* Company logo and proptech details */}
            <div className="left_col-place">
              <div className="nav-top flex">
                <i
                  className="image-wrapper cursor-pointer"
                  onClick={(e) => navigateToDetails()}
                >
                  {proptech?.companyLogo ? (
                    <Tooltip
                      title={proptech?.proptechCompany?.name}
                      arrow
                      PopperProps={{ style: { zIndex: 1 } }}
                    >
                      <img
                        src={proptech?.companyLogo}
                        alt="Logo"
                        className="object-contain"
                      />
                    </Tooltip>
                  ) : (
                    <HideImageOutlinedIcon />
                  )}
                </i>
                <div className="card-info">
                  <h2>
                    <span
                      className="cursor-pointer"
                      onClick={(e) => navigateToDetails()}
                    >
                      {proptech?.proptechCompany?.name}
                    </span>
                  </h2>
                  <ul className="listing-item-place badge-item">
                    {proptech?.region ? (
                      <Tooltip
                        title={TooltipText.companyRegion}
                        arrow
                        PopperProps={{ style: { zIndex: 1 } }}
                      >
                        <li>
                          <img
                            src={mapIcon}
                            alt="Icon"
                            width={18}
                            height={18}
                          />
                          {proptech?.region?.name}
                        </li>
                      </Tooltip>
                    ) : null}
                    {/* {proptech?.proptechAnalysisTeam ? (
                      <Tooltip
                        title={TooltipText.employeesCount}
                        arrow
                        PopperProps={{ style: { zIndex: 1 } }}
                      >
                        <li>
                          <img src={employeeIcon} alt="Icon" />
                          {
                            proptech?.proptechAnalysisTeam
                              ?.proptechAnalysisNoOfEmployee?.name
                          }{" "}
                          Employees
                        </li>
                      </Tooltip>
                    ) : null} */}
                    {/* {proptech?.investmentStage ? (
                      <Tooltip
                        title={TooltipText.investmentStage}
                        arrow
                        PopperProps={{ style: { zIndex: 1 } }}
                      >
                        <li>
                          <img src={paymentIcon} alt="Icon" />
                          {proptech?.investmentStage?.name}
                        </li>
                      </Tooltip>
                    ) : null} */}
                    {proptech?.proptechAnalysisOverview?.techOffering1 ? (
                      <Tooltip
                        title={TooltipText.techOffering}
                        arrow
                        PopperProps={{ style: { zIndex: 1 } }}
                      >
                        <li>
                          <img
                            src={techofferingIcon}
                            alt="Icon"
                            width={18}
                            height={18}
                          />
                          {
                            proptech?.proptechAnalysisOverview
                              ?.proptechAnalysisTechOffering1?.name
                          }
                        </li>
                      </Tooltip>
                    ) : null}
                  </ul>
                </div>
              </div>
              <p>{proptech?.elevatorPitchAdmin}</p>
              <div className="bottom-menu-card-place flex content-center flex-wrap font-bold">
                <div className="important-sectors py-1">
                  <h2>{PROPTECHCARD?.sectors}</h2>
                  <ul className="menu-item">
                    <KeyFeatureLabel
                      icon={
                        proptech?.proptechAnalysisOverview
                          ?.proptechAnalysisSector1?.icon
                      }
                      label={
                        proptech?.proptechAnalysisOverview
                          ?.proptechAnalysisSector1?.name || "-"
                      }
                    />
                    <KeyFeatureLabel
                      icon={
                        proptech?.proptechAnalysisOverview
                          ?.proptechAnalysisSector2?.icon
                      }
                      label={
                        proptech?.proptechAnalysisOverview
                          ?.proptechAnalysisSector2?.name || "-"
                      }
                    />
                  </ul>
                </div>
                {proptech?.keyCustomer1 || proptech?.keyCustomer2 ? (
                  <div className="key-customers py-1">
                    <h2>{PROPTECHCARD?.keyCustomers}</h2>
                    <ul className="menu-item">
                      <Tooltip
                        title={proptech?.keyCustomer1 || "-"}
                        arrow
                        PopperProps={{ style: { zIndex: 1 } }}
                      >
                        {proptech?.keyCustomer1Logo ? (
                          <li>
                            <img
                              src={proptech?.keyCustomer1Logo}
                              alt="Icon"
                              className="object-contain"
                            />
                          </li>
                        ) : (
                          <li>
                            <HideImageOutlinedIcon />
                          </li>
                        )}
                      </Tooltip>
                      {proptech?.keyCustomer2 && (
                        <Tooltip
                          title={proptech?.keyCustomer2}
                          arrow
                          PopperProps={{ style: { zIndex: 1 } }}
                        >
                          {proptech?.keyCustomer2Logo ? (
                            <li>
                              <img
                                src={proptech?.keyCustomer2Logo}
                                alt="Icon"
                                className="object-contain"
                              />
                            </li>
                          ) : (
                            <li>
                              <HideImageOutlinedIcon />
                            </li>
                          )}
                        </Tooltip>
                      )}
                    </ul>
                  </div>
                ) : null}
                <div className="btn-wrapper">
                  <span
                    className="icon-btn cursor-pointer"
                    onClick={(e) => navigateToDetails()}
                  >
                    Read more
                    <img src={leftArowIcon} alt="Icon" />
                  </span>
                </div>
              </div>
            </div>
            {/* Proptech chart */}
            {proptech?.proptechAnalysisOverview &&
            rffProptechs?.rffMasterData?.investmentStageData ? (
              <div
                className="right_col-place"
                onClick={(e) => e.stopPropagation()}
              >
                <Chart
                  items={chartData.map(
                    (val) => proptech?.proptechAnalysisOverview[val]
                  )}
                  labels={chartLabels}
                  step={2}
                  label={"Rating"}
                  maximumValue={10}
                  maintainAspectRatio={true}
                />
              </div>
            ) : (
              <div
                className="right_col-place"
                onClick={(e) => e.stopPropagation()}
              >
                <Chart
                  items={[null, null, null, null, null, null]}
                  labels={chartLabels}
                  step={2}
                  label={"Rating"}
                  maximumValue={10}
                  maintainAspectRatio={true}
                />
              </div>
            )}
          </div>
          {/* Note modal */}
          <Modal
            open={openNoteModal}
            onClose={handleNoteCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="meeting-cancel-modal modal"
          >
            <>
              <Box sx={style}>
                <IconButton
                  className="close-btn"
                  onClick={() => handleNoteCloseModal()}
                >
                  <img src={closeIcon} alt="Icon" />
                </IconButton>
                <div className="modal-outer relative">
                  <div className="modal-inner">
                    <h2>Add a note</h2>
                  </div>
                  <form>
                    <div className="form-wrapper">
                      <div className="form-group">
                        <PTInput
                          id="note"
                          name="note"
                          className="form-control textarea-input"
                          multiline
                          maxRows={1}
                          placeholder="Enter a note for later"
                          label="Enter a note for later"
                          fullWidth
                          value={noteData}
                          handleOnChangeEvent={(e) =>
                            setNoteData(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="btn-wrapper h-11">
                  <PTButton
                    label="Cancel"
                    className="fill-gray btn h-11"
                    handleOnClickEvent={() => handleNoteCloseModal()}
                  />
                  <PTButton
                    label="Save"
                    handleOnClickEvent={() => handleNoteSubmit()}
                    className="fill-blue btn h-11"
                  />
                </div>
              </Box>
            </>
          </Modal>
        </div>
      )}
      {/* Share modal */}
      {openShareModal && (
        <ShareModal
          shareMessage={shareMessage}
          openShareModal={openShareModal}
          handleShareCloseModal={() => handleShareCloseModal()}
          activeUsers={activeUsers}
          proptechId={proptech?.id}
        />
      )}
    </>
  );
}
