import { Box, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PTButton from "../../../components/GenericComponents/PTButton";
import PTInput from "../../../components/GenericComponents/PTInput";
import closeIcon from "../../../assets/Icons/ic-cross.svg";
import EmailField from "../EmailField";
import "./ShareModal.scss";
import { shareProptech } from "../../utils/middleware/Dashboard";
import { SHARE_MODAL } from "../../constants";
import { checkForOnlyWhiteSpace } from "../../utils/utility/utility";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const linkFontStyle = {
  fontSize: "12px",
  height: "36px",
};

export default function ShareModal({
  openShareModal,
  handleShareCloseModal,
  activeUsers,
  proptechId,
}) {
  const pageLink = `${window.location.origin}/proptech/${proptechId}`;
  const rffActiveUsers = activeUsers;
  const [shareMessage, setShareMessage] = useState(SHARE_MODAL.shareMsg);
  const [emailList, setEmailList] = useState([]);
  const [linkCopied, setLinkCopied] = useState(false);

  const handleShareProptech = () => {
    let emailIds = emailList.map((data) => data.id);
    shareProptech(proptechId, emailIds, shareMessage);
  };

  const handleCopyLink = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(pageLink);
      setLinkCopied(true);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = pageLink;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      setLinkCopied(true);
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleCloseModal = () => {
    handleShareCloseModal();
    setLinkCopied(false);
    setEmailList([]);
    setShareMessage(SHARE_MODAL.shareMsg);
  };

  return (
    <div>
      <Modal
        open={openShareModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal share-modal"
      >
        <>
          <Box sx={style}>
            <IconButton
              className="close-btn"
              onClick={() => handleCloseModal()}
            >
              <img src={closeIcon} alt="Icon" />
            </IconButton>
            <div className="modal-outer relative">
              <div className="modal-inner">
                <h2>{SHARE_MODAL.share}</h2>
              </div>
              <form>
                <div className="form-wrapper">
                  <div className="form-group">
                    <EmailField
                      options={rffActiveUsers || []}
                      onChange={(e, value) => setEmailList(value)}
                    />
                    <div
                      className={`form-group ${
                        shareMessage ? "" : "error-parent"
                      }`}
                    >
                      <PTInput
                        id="message"
                        name="message"
                        className="form-control textarea-input"
                        multiline
                        maxRows={1}
                        placeholder="Message"
                        label="Message"
                        fullWidth
                        value={shareMessage}
                        handleOnChangeEvent={(e) =>
                          setShareMessage(e.target.value)
                        }
                        maxLength={255}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="btn-wrapper-share-modal">
              <PTButton
                label="Share"
                handleOnClickEvent={() => handleShareProptech()}
                size="large"
                className={`${
                  emailList.length &&
                  shareMessage &&
                  !checkForOnlyWhiteSpace(shareMessage)
                    ? "fill-blue"
                    : "disable-share-btn"
                } btn mb-[24px]`}
                disabled={
                  !emailList.length ||
                  !shareMessage ||
                  checkForOnlyWhiteSpace(shareMessage)
                }
              />
            </div>
            <hr></hr>
            <h2 className="text-sm my-4">{SHARE_MODAL.shareLink}</h2>
            <div className="flex justify-between flex-col items-start sm:flex-row">
              <div className="flex-0 w-full sm:flex-1">
                <PTInput
                  placeholder="Link"
                  label=""
                  fullWidth
                  value={pageLink}
                  className="text-xs"
                  InputPropsStyle={linkFontStyle}
                />
              </div>
              <PTButton
                label={`${linkCopied ? "Copied" : "Copy link"}`}
                className={`btn ${
                  !linkCopied ? "fill-gray" : "fill-green"
                } min-w-[124px] mt-4 sm:mt-0 ml-0 sm:ml-4`}
                startIcon={!linkCopied ? <ContentCopyIcon /> : null}
                handleOnClickEvent={() => handleCopyLink()}
                size="small"
              />
            </div>
          </Box>
        </>
      </Modal>
    </div>
  );
}
