export function overviewTabRFFDTO(data) {
  return {
    id: data?.id || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    userExperience:
      data?.userExperience || data?.userExperience === 0
        ? data?.userExperience
        : null,
    dealClosing:
      data?.dealClosing || data?.dealClosing === 0 ? data?.dealClosing : null,
    evolvingAssets:
      data?.evolvingAssets || data?.evolvingAssets === 0
        ? data?.evolvingAssets
        : null,
    decisionMaking:
      data?.decisionMaking || data?.decisionMaking === 0
        ? data?.decisionMaking
        : null,
    smarterBuilds:
      data?.smarterBuilds || data?.smarterBuilds === 0
        ? data?.smarterBuilds
        : null,
    connectivity:
      data?.connectivity || data?.connectivity === 0
        ? data?.connectivity
        : null,
    trackRecord:
      data?.trackRecord || data?.trackRecord === 0 ? data?.trackRecord : null,
    proptechCompanyDetails: {
      technologyProductAdmin:
        data?.proptechCompanyDetails?.technologyProductAdmin || "-",
      founderPitchVideo: data?.proptechCompanyDetails?.founderPitchVideo || "",
    },
    proptechAnalysisSector1: {
      id: data?.proptechAnalysisSector1?.id || 0,
      icon: data?.proptechAnalysisSector1?.icon || null,
      name: data?.proptechAnalysisSector1?.name || "-",
    },
    proptechAnalysisSector2: {
      id: data?.proptechAnalysisSector2?.id || 0,
      icon: data?.proptechAnalysisSector2?.icon || null,
      name: data?.proptechAnalysisSector2?.name || "-",
    },
    proptechAnalysisSector3: {
      id: data?.proptechAnalysisSector3?.id || 0,
      icon: data?.proptechAnalysisSector3?.icon || null,
      name: data?.proptechAnalysisSector3?.name || "-",
    },
    proptechAnalysisUseCase1: {
      id: data?.proptechAnalysisUseCase1?.id || 0,
      icon: data?.proptechAnalysisUseCase1?.icon || null,
      name: data?.proptechAnalysisUseCase1?.name || "-",
    },
    proptechAnalysisUseCase2: {
      id: data?.proptechAnalysisUseCase2?.id || 0,
      icon: data?.proptechAnalysisUseCase2?.icon || null,
      name: data?.proptechAnalysisUseCase2?.name || "-",
    },
    proptechAnalysisUseCase3: {
      id: data?.proptechAnalysisUseCase3?.id || 0,
      icon: data?.proptechAnalysisUseCase3?.icon || null,
      name: data?.proptechAnalysisUseCase3?.name || "-",
    },
    proptechAnalysisTechOffering1: {
      id: data?.proptechAnalysisTechOffering1?.id || 0,
      icon: data?.proptechAnalysisTechOffering1?.icon || null,
      name: data?.proptechAnalysisTechOffering1?.name || "-",
    },
    proptechAnalysisTechOffering2: {
      id: data?.proptechAnalysisTechOffering2?.id || 0,
      icon: data?.proptechAnalysisTechOffering2?.icon || null,
      name: data?.proptechAnalysisTechOffering2?.name || "-",
    },
    proptechAnalysisTechOffering3: {
      id: data?.proptechAnalysisTechOffering3?.id || 0,
      icon: data?.proptechAnalysisTechOffering3?.icon || null,
      name: data?.proptechAnalysisTechOffering3?.name || "-",
    },
  };
}
