import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../components/Loading";
import NotFound from "../pages/NotFound";

// Place
import PlacePublicRoute from "../rff/routes/PublicRoute";
import PlacePrivateRoute from "../rff/routes/PrivateRoute";
import PlaceLanding from "../rff/pages/Landing";
import PlaceInterest from "../rff/pages/Interest";
import PlaceLogin from "../rff/pages/Login";
import PlaceSignup from "../rff/pages/Signup";
import PlaceResetPassword from "../rff/pages/ResetPassword";
import PlaceForgotPassword from "../rff/pages/ForgotPassword";
import PlaceCheckEmail from "../rff/pages/CheckEmail";
import PlaceEmailVerification from "../rff/pages/EmailVerification";
import PlaceDashboard from "../rff/pages/Dashboard";
import PlaceProptechDetail from "../rff/pages/ProptechDetail";
import PlacePublicAuth from "../rff/pages/PlacePublicProptechDetailsPage/Auth";
import PublicPlaceProptechDetails from "../rff/pages/PlacePublicProptechDetailsPage/ProptechDetails";

function Router() {
  const { isLoading } = useSelector((state) => state.loader);

  return (
    <BrowserRouter>
      {isLoading ? <Loader /> : null}
      <Routes>
        {/* Place Routes */}
        <Route
          path="/"
          exact
          element={
            <PlacePublicRoute>
              <PlaceLanding />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/login"
          exact
          element={
            <PlacePublicRoute>
              <PlaceLogin />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/signup"
          exact
          element={
            <PlacePublicRoute>
              <PlaceSignup />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/thanksforinterest"
          exact
          element={
            <PlacePublicRoute>
              <PlaceInterest />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/forgotpassword"
          exact
          element={
            <PlacePublicRoute>
              <PlaceForgotPassword />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/resetpassword"
          exact
          element={
            <PlacePublicRoute>
              <PlaceResetPassword />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/checkemail"
          exact
          element={
            <PlacePublicRoute>
              <PlaceCheckEmail />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/emailverification"
          exact
          element={
            <PlacePublicRoute>
              <PlaceEmailVerification />
            </PlacePublicRoute>
          }
        />
        <Route
          path="/dashboard"
          exact
          element={
            <PlacePrivateRoute>
              <PlaceDashboard />
            </PlacePrivateRoute>
          }
        />
        <Route
          path="/proptech/:proptechId"
          exact
          element={
            <PlacePrivateRoute>
              <PlaceProptechDetail />
            </PlacePrivateRoute>
          }
        />
        <Route
          path="/shared/:id"
          exact
          element={
            <>
              <PlacePublicAuth />
            </>
          }
        />
        <Route
          path="/shared/:id/proptech"
          exact
          element={
            <>
              <PublicPlaceProptechDetails />
            </>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
