export function marketInsightsTabRFFDTO(data) {
  return {
    id: data?.id || 0,
    proptechCompanyDetailsId: data?.proptechCompanyDetailsId || 0,
    futureSynopsis: data?.futureSynopsis || "-",
    future1Heading: data?.future1Heading || "-",
    future1: data?.future1 || "-",
    future2Heading: data?.future2Heading || "-",
    future2: data?.future2 || "-",
    future3Heading: data?.future3Heading || "-",
    future3: data?.future3 || "-",
    stat1Type: data?.stat1Type || "-",
    stat1: data?.stat1 || "-",
    stat2Type: data?.stat2Type || "-",
    stat2: data?.stat2 || "-",
    stat3Type: data?.stat3Type || "-",
    stat3: data?.stat3 || "-",
    stat4Type: data?.stat4Type || "-",
    stat4: data?.stat4 || "-",
    needToKnow1Text: data?.needToKnow1Text || "-",
    needToKnow2Text: data?.needToKnow2Text || "-",
    needToKnow3Text: data?.needToKnow3Text || "-",
    proptechAnalysisTrend1: {
      id: data?.proptechAnalysisTrend1?.id || 0,
      icon: data?.proptechAnalysisTrend1?.icon || null,
      name: data?.proptechAnalysisTrend1?.name || "-",
    },
    proptechAnalysisTrend2: {
      id: data?.proptechAnalysisTrend2?.id || 0,
      icon: data?.proptechAnalysisTrend2?.icon || null,
      name: data?.proptechAnalysisTrend2?.name || "-",
    },
    proptechAnalysisTrend3: {
      id: data?.proptechAnalysisTrend3?.id || 0,
      icon: data?.proptechAnalysisTrend3?.icon || null,
      name: data?.proptechAnalysisTrend3?.name || "-",
    },
    proptechAnalysisPrediction1: {
      id: data?.proptechAnalysisPrediction1?.id || 0,
      icon: data?.proptechAnalysisPrediction1?.icon || null,
      name: data?.proptechAnalysisPrediction1?.name || "-",
    },
    proptechAnalysisPrediction2: {
      id: data?.proptechAnalysisPrediction2?.id || 0,
      icon: data?.proptechAnalysisPrediction2?.icon || null,
      name: data?.proptechAnalysisPrediction2?.name || "-",
    },
    proptechAnalysisPrediction3: {
      id: data?.proptechAnalysisPrediction3?.id || 0,
      icon: data?.proptechAnalysisPrediction3?.icon || null,
      name: data?.proptechAnalysisPrediction3?.name || "-",
    },
    proptechAnalysisGrowth1: {
      id: data?.proptechAnalysisGrowth1?.id || 0,
      icon: data?.proptechAnalysisGrowth1?.icon || null,
      name: data?.proptechAnalysisGrowth1?.name || "-",
    },
    proptechAnalysisGrowth2: {
      id: data?.proptechAnalysisGrowth2?.id || 0,
      icon: data?.proptechAnalysisGrowth2?.icon || null,
      name: data?.proptechAnalysisGrowth2?.name || "-",
    },
    proptechAnalysisGrowth3: {
      id: data?.proptechAnalysisGrowth3?.id || 0,
      icon: data?.proptechAnalysisGrowth3?.icon || null,
      name: data?.proptechAnalysisGrowth3?.name || "-",
    },
    proptechAnalysisNeedToKnow1Heading: {
      id: data?.proptechAnalysisNeedToKnow1Heading?.id || 0,
      icon: data?.proptechAnalysisNeedToKnow1Heading?.icon || null,
      name: data?.proptechAnalysisNeedToKnow1Heading?.name || "-",
    },
    proptechAnalysisNeedToKnow2Heading: {
      id: data?.proptechAnalysisNeedToKnow2Heading?.id || 0,
      icon: data?.proptechAnalysisNeedToKnow2Heading?.icon || null,
      name: data?.proptechAnalysisNeedToKnow2Heading?.name || "-",
    },
    proptechAnalysisNeedToKnow3Heading: {
      id: data?.proptechAnalysisNeedToKnow3Heading?.id || 0,
      icon: data?.proptechAnalysisNeedToKnow3Heading?.icon || null,
      name: data?.proptechAnalysisNeedToKnow3Heading?.name || "-",
    },
  };
}
