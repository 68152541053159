export const configs = {
  api:
    process.env.REACT_APP_BACKEND_URL || "https://devapi.connectbytheptc.com",
  siteKey:
    process.env.REACT_APP_RECAPTCHA_PLACE_SITE_KEY ||
    "6Lf2zw4nAAAAAM5acaU9AOX5RBlvyFIDMLA-mssV",
  calendlyToken:
    process.env.REACT_APP_CALENDLY_TOKEN ||
    "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjcwMjE2MDU4LCJqdGkiOiJlNjY4MWU2MC1kMzEzLTQxYmItYTg3Zi04YzlkYmIwYzE3ZDgiLCJ1c2VyX3V1aWQiOiI5ZTQwNjYyMS0zYjhjLTRmOWMtYjEyZC04ZWVhMzI4OTkxZTcifQ.PscZ2C1n5F56gQGpW9pFot512yB0QEfIVZ1idLwuidMpi1JwrcVA7rNVxaJzf627z2Zanivq-jw9Ll85KwOI4Q",
  googleMapApiKey:
    process.env.REACT_APP_GOOGLE_MAP_API_KEY ||
    "AIzaSyCGg0wdXU1_PWcnRSQ2GlOKMb5Jgmr86Ss",
};
